import Colors from '../Color';

const styles = {
  primaryButton: {
    backgroundColor: Colors.primaryColor,
    color: Colors.primaryFontColor,
    borderColor: Colors.primaryColor
  },
  secondaryButton: {
    backgroundColor: Colors.secondaryColor,
    color: Colors.primaryColor,
    borderColor: Colors.primaryColor
  },
  secondaryFonts: {
      color: Colors.secondaryFontColor
  },
  primaryFonts: {
      color: Colors.primaryColor
  },
  locationSecondaryStyle: {
    borderColor: Colors.primaryColor,
    color: Colors.secondaryColor
  },
  primaryDiv: {
    backgroundColor: Colors.primaryColor,
    color: Colors.primaryFontColor
  },
  secondaryDiv: {
     backgroundColor: Colors.secondaryColor,
    color: Colors.secondaryFontColor
  },
  themeBg: {
     backgroundColor: Colors.themeColor,
     height: "100%"
  }, 
  header: {
    backgroundColor: Colors.primaryColor
  },
  searchBackground: {
    borderBottom: "2px solid " + Colors.primaryColor
  },
  modalStyle1: {
    content : {
      bottom                : 'auto',
      left                  : '50%',
      right                 : 'auto',
      top                   : '20%',
      marginRight           : '-50%',
      width                 :  window.screen.width > 800 ? '20%' : '100%',
      transform             : 'translate(-50%, 0%)',
      borderRadius          : "5px",
      padding               : "30px"
    }
  },
  modalStyle2: {
    content : {
      bottom                : 'auto',
      left                  : '50%',
      right                 : 'auto',
      top                   : window.screen.width > 800 ? '5%': '5%',
      marginRight           : '-50%',
      width                 :  window.screen.width > 800 ? '50%' : '90%',
      transform             : 'translate(-50%, 0%)',
      maxHeight             :  window.screen.width > 800 ? '75%' : '100%',
      borderRadius          : "10px",
      padding               : "10px"
    }
  },
  modalStyle3: {
    content : {
      bottom                : '0px',
      left                  : window.screen.width > 800 ? '80%' : '50%',
      right                 : '0px',
      top                   : '0px',
      marginRight           : '0px',
      width                 :  window.screen.width > 800 ? '20%' : '50%',
      maxHeight             : '100%',
      borderRadius          : "0px",
      padding               : "0px",
      borderTopRightRadius  : "0px",
      borderTopLeftRadius   : "0px",
      zIndex: "3"
    }
  },
  modalStyle4: {
    content : {
      bottom                : '0px',
      left                  : window.screen.width > 800 ? '0%' : '0%',
      right                 : '0px',
      top                   : '0px',
      marginRight           : '0px',
      width                 :  window.screen.width > 800 ? '100%' : '100%',
      borderRadius          : "0px",
      padding               : "0px",
      borderTopRightRadius  : "0px",
      borderTopLeftRadius   : "0px",
      zIndex: "3",
      paddingTop: "80px",
      backgroundColor: "transparent"
    }
  }
}

export default styles;