import React from "react";
import "../../Assets/Style/styles.scss";
import Loading from '../Shared/loader'
import ReaderVideo from "./video"
import ReaderBook from "./book"
import TruncateBook from "./truncateBook"

const Main = props => {
    let loading =  props.state.loading;
    let downloaded = !props.state.downloaded;
    let tocs = props.content ?? null;
    let count = props.chapterCount;
    let chapters = props.titleSummary ? props.titleSummary.toc.chapters : null;
    let sampleChapter = props.titleSummary ? props.titleSummary.sampleChapter : null;
    let nextChapter = (props.titleSummary && props.state.section <= props.chapterCount) ? props.titleSummary.toc.chapters[eval(props.state.section)].sections[0].id : null;
    let previousChapter = props.titleSummary && props.state.section > 1 ? props.titleSummary.toc.chapters[eval(props.state.section) -2].sections[0].id : null;
    return (
       <div className='reader'>

           <Loading
               loading={!downloaded ? loading : false}
           />
           {(downloaded || !loading) &&
           <>
               {props.state.key <= count &&
                <div className="reader-content">
                    {props.state.type === 'videos' &&
                      <ReaderVideo
                          content={tocs}
                          chapters={chapters}
                          count={count}
                          state={props.state}
                          loggedIn={props.loggedIn}
                      />
                    }
                    {props.state.type !== 'videos' &&
                        <>
                            {(downloaded || (props.state.type === 'books' && props.loggedIn)) &&
                                <ReaderBook
                                    content={tocs}
                                    count={count}
                                    state={props.state}
                                    loggedIn={props.loggedIn}
                                    nextChapter={nextChapter}
                                    previousChapter={previousChapter}
                                />
                            }
                            {props.state.type === 'books' && !props.loggedIn &&
                                <TruncateBook
                                    sampleChapter={sampleChapter}
                                    content={tocs}
                                    count={count}
                                    state={props.state}
                                    loggedIn={props.loggedIn}
                                    nextChapter={nextChapter}
                                    previousChapter={previousChapter}
                                />
                            }
                        </>
                    }
                </div>
               }
           </>
           }
       </div>
   )
};

export default Main;