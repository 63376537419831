import React from "react";
import "../../Assets/Style/styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";

const Main = props => {
   var ratingHTML = [];
   for(var i = 1; i<= props.rating; i++) {
      ratingHTML.push(<FontAwesomeIcon icon={faStar} />);
   }
   if(props.rating > 0 && props.rating%1 != 0) {
      ratingHTML.push(<FontAwesomeIcon icon={faStarHalf} />);  
   }
   return (
      <div className="star-rating row">
         <div className="col-12" style={{color: props.color}}>
            {ratingHTML}
         </div>
      </div>
   )
};

export default Main;
