import React, {useEffect, useState} from "react";
import "../../Assets/Style/styles.scss";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import styles from "../../Assets/Style/";

const Main = props => {
    let tocs = props.content;
    let count = props.count;
    const [selectedImage, setSelectedImage] = useState("");

    useEffect(() => {
        const card = document.querySelectorAll('.mediaobject img');
        if(card.length > 0) {
            card.forEach((e) => {
                e.addEventListener('click', function () {
                    setSelectedImage(e.getAttribute("src"));
                });
            })
        }    
    });

    return (
        <>
            { tocs && Object.keys(tocs).map(function(keyName, keyIndex) {
                 return (
                     <div className="row" key={keyName}>
                         <div className="col">
                             <div className="book-content">
                                <div dangerouslySetInnerHTML={{__html: tocs[keyName].content }}/>
                             </div>
                         </div>
                     </div>
                 )
           })}
            <hr/>
            <div className="row mt-5 p-4">
                <div className="col-3 text-center">
                    {props.state.key > 1 &&
                    <Link className="btn primary-btn previous"  to={
                        "/book/"+props.state.category + "/" + props.state.isbn + "/" + eval(props.state.key) + "/"  + props.previousChapter
                    }>
                        <FontAwesomeIcon icon={faChevronLeft} className="fa-lg"/>
                    </Link>
                    }
                </div>
                <div className="col-6 text-center">
                    {props.state.key <= (count - 1) &&
                    <>
                        End of Chapter {(props.state.key + 1)}
                    </>
                    }
                </div>
                <div className="col-3 text-center">
                    {props.state.key <= (count -1) &&
                    <Link className="btn primary-btn next" to={
                        "/book/"+props.state.category + "/" + props.state.isbn + "/" + (eval(props.state.key) + 2) + "/" + props.nextChapter
                        }
                    >
                        <FontAwesomeIcon icon={faChevronRight} className="fa-lg"/>
                    </Link>
                    }
                </div>
                {selectedImage && <Modal
                    isOpen={selectedImage != ""}
                    style={styles.modalStyle4}
                    contentLabel="Information"
                    key="filtersModal"
                    ariaHideApp={false}
                    onRequestClose={() => setSelectedImage("")}
                >
                    <FontAwesomeIcon className="close-icon text-white" icon={faTimes} onClick={() => setSelectedImage()} />
                    <img src={selectedImage} className="width-100" />
                </Modal>}
            </div>
       </>
    )
}

export default Main;