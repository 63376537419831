import React from "react";
import "../../Assets/Style/styles.scss";

const Loader = (props) => {
    let loading = props.loading;
    return (
        <>
            {loading && <div className='loading mt-auto'/>}
        </>
    )
}

export default Loader;