import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronRight, faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button} from "reactstrap";
import { TocContainer } from "../../Components";
const Main = props => {
    function downloadHandler(value) {
       props.downloadOffline(value)
    }
    let downloaded = !!props.downloaded;
    let downloadable = props.state.type === 'books';
    return (
        <>
            {props.titleSummary &&
            <>
                {!props.show &&
                    <>
                        <Button className="btn btn-primary open-reader-navigation" onClick={props.toggle}>
                            <FontAwesomeIcon icon={faChevronRight} size="lg"/>
                        </Button>
                    </>
                }
                {props.show &&
                <div className="reader-navigation">
                    <div className="wrapper border-sm shadow mt-3">
                        <div className="reader-navigation-header mt-5">
                            <div className="row">
                                <div className="col">
                                    <Button className="btn btn-primary reader-navigation--close" onClick={props.toggle}>
                                        <FontAwesomeIcon icon={faTimes} size="lg"/>
                                    </Button>
                                </div>
                            </div>
                            <div className="row mt-3 px-3">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                                    <img src={props.titleSummary.coverImage} className="reader-navigation--image"/>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                                    <div className="row">
                                        <div className="col p-2">
                                            {props.titleSummary.title}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col p-2">
                                            {props.titleSummary.oneLiner}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col text-center pt-5 mt-2">
                                            {props.loggedIn && downloadable &&
                                                <>
                                                    {props.state.downloading &&
                                                        <FontAwesomeIcon icon={faSpinner} className="fa-spin"/>
                                                    }

                                                    {downloaded  &&
                                                       <>
                                                        <FontAwesomeIcon icon={faCheck} className="text-orange mr-3"/> Downloaded!
                                                       </>
                                                    }
                                                    {!props.state.downloading && !downloaded &&
                                                        <Button className="btn primary-btn" onClick={() => downloadHandler(props.state.section)}>Download for Offline</Button>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </div>
                        <div className="reader-navigation-chapters">
                            <TocContainer
                                toc={props.titleSummary.toc}
                                state={props.state}
                            />
                        </div>
                    </div>
                </div>
                }
            </>
            }
        </>
    );
}

export default Main;