import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import {Button, UncontrolledCollapse} from "reactstrap";
import {Link} from "react-router-dom";
const Main = props => {
    let chapters = props.toc.chapters ?? null;
    let completed = props.state.completed ?? null;
   return ( 
    <div className="row toc-container">
        <ul>
            {chapters && Object.keys(chapters).map(function(keyName, keyIndex) {
                return (
                    <li key={"chapters-" +keyName}>
                        <div className="toc-container-toggle" id={"toggle-" + keyName}>
                            <div className="row">
                                <div className="col-1">
                                    <div className="toc-container-single-chart">
                                        <svg viewBox="0 0 36 36" className={`circular-chart orange`}>
                                            <path className="circle-bg"
                                                  d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                            />
                                            <path className="circle"
                                                  strokeDasharray={`${
                                                            completed && completed[eval(keyName)+1] 
                                                          ? Object.keys(completed[eval(keyName) +1]).length / (chapters[keyName].sections.length / 100) 
                                                          : 0
                                                        }, 100`}
                                                  d="M18 2.0845
                                                    a 15.9155 15.9155 0 0 1 0 31.831
                                                    a 15.9155 15.9155 0 0 1 0 -31.831"
                                            />
                                            <text x="18" y="22.35" className="percentage">{eval(keyName ) + 1}</text>
                                        </svg>
                                    </div>
                                </div>
                                <div className="col-9">
                                    <span className="toc-container-toggle-title">{chapters[keyName].title}</span>
                                </div>
                                <div className="col-1">
                                    <FontAwesomeIcon icon={faAngleDown} size="lg" className="toc-container-toggle-icon"/>
                                </div>
                            </div>
                        </div>
                        <UncontrolledCollapse toggler={"#toggle-" + keyName}>
                            <ul className="toc-container-sections">
                            {chapters && Object.keys(chapters[keyName].sections).map(function(key, keyIndex) {
                                return (
                                    <li className="toc-container-sections-list" key={"section-" + key}>
                                        <div className="row">
                                            <div className="col-1">
                                                {completed && completed[eval(keyName)+1] && completed[eval(keyName) +1][chapters[keyName].sections[key].id] && <FontAwesomeIcon icon={faCheck} size="lg" className="toc-container-toggle-icon float-left"/>}
                                            </div>
                                            <div className="col">
                                                <Link to={
                                                    "/book/"+props.state.category + "/" + props.state.isbn +"/" + chapters[keyName].id + "/" + chapters[keyName].sections[key].id
                                                } className="toc-container-sections-list-link">
                                                    {chapters[keyName].sections[key].title}
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                            </ul>
                        </UncontrolledCollapse>
                        <hr/>
                    </li>
                )
            })}
        </ul>
    </div>
  );
}

export default Main;