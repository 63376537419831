import React from "react";
import { handleOnClick } from "../../Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const Main = props => {
  return (
    <div className={props.className}>
      <div className="arrow-up top-arrow"></div>
      <div className="row">
         <div className="col-12">
             <Link to={{pathname : "https://subscription.packtpub.com/my-account"}} target="_BLANK"> My Account</Link>
         </div>
         {/*<div className="col-12">*/}
         {/*   My Subscription*/}
         {/*</div>*/}
         {/*<div className="col-12">*/}
         {/*   My Orders*/}
         {/*</div>*/}
      </div>
      <hr />
       <div className="row" onClick={() => props.signOut()}>
         <div className="col-12">
            Sign out
         </div>
      </div>
    </div>
  );
};

export default Main;