import React from "react";
import "../../Assets/Style/styles.scss";
import styles from "../../Assets/Style/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import {history} from "../../Utils/history";

const Main = props => {
   return (
      <div className="filters-bar row">
         <div className="col-12 filters-bar-header">
            Showing {props.nbHits} results {props.keywords ? " for keywords '" + props.keywords + "'" : ""}
         </div>
         <br />
         <br />
         <div className="col-4 col-sm-4 col-lg-0">
            <Button className="primary-btn btn" onClick={() => props.setFiltersModal()}>Filter</Button>
         </div>
         <div className="col-5 col-sm-5 col-lg-9 col-md-9">
            <select onChange={(e) => props.setFilters("sort-by", e.target.value)} placeholder="Sort by.." value={props.sortBy}  className="filter-dropdown" readOnly>
               <option value="subs_search_prod">Most Popular</option>
               <option value="subs_search_prod_date_order">Recently Published</option>
            </select>
         </div>
         <div className="col-3 col-lg-3 col-md-3 col-sm-3">
             <select onChange={(e) => props.setFilters("per-page", e.target.value)} placeholder="Per Page" value={props.perPage}  className="filter-dropdown" readOnly>
               <option>24</option>
               <option>48</option>
               <option>96</option>
            </select>
         </div>
      </div>
   )
};

export default Main;
