import React from "react";
import AppRouter from "./routes";
import "./CommonStyles/reset.scss";
import {history} from "./Utils/history";
import {DEFAULT_PATH} from "./Config";
export default function AppRoot(props) {
try {
  return <AppRouter />;
  } catch(e) {
	alert(e);
}
}

setTimeout(() => {
  document.getElementById('splash').style.display = 'none';
}, 1000);