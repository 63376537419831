import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Main = props => {
  let listArr = [];
  let listArr2 = [];
  let listArrDots = [];
  listArrDots.push(<a className="text-blue">..</a>);
  let dots = false;
   for (var i = 1; i <= props.nbPages; i++) {
      if((i >= props.pageNo - 2 && i <= props.pageNo + 3)) {
        let j = i;
        listArr.push(<a className={props.pageNo == i ? "text-orange" : "text-blue"} key={"page-" + i} onClick={() => props.setFilters("page-no", j)}> {i} </a>)  
      }
      if((i >= props.nbPages - 3) && props.pageNo < props.nbPages - 6) {
        let j = i;
        listArr2.push(<a className={props.pageNo == i ? "text-orange" : "text-blue"} key={"page-" + i} onClick={() => props.setFilters("page-no", j)}> {i} </a>);  
      }
    }
    if(props.pageNo < props.nbPages - 6) {
      listArr = [...listArr, ...listArrDots, ...listArr2];
    }
  return (
    <div className="container text-center margin-top-50 pagin">
      <a onClick={() => props.pageNo > 1 ? props.setFilters("page-no", props.pageNo - 1) : {}} className={props.pageNo == 1 ? "text-gray" : "text-blue"}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </a>
      {listArr}
      <a  onClick={() => props.pageNo < props.nbPages ? props.setFilters("page-no", props.pageNo + 1) : {}} className={props.pageNo == props.nbPages ? "text-gray" : "text-blue"}>
        <FontAwesomeIcon icon={faAngleRight} />
      </a>
    </div>
  );
}

export default Main;