import React from "react";

import image from "../../Assets/Images/logo.png";
import share from "../../Assets/Images/apple-share.png";

const Main = props => {
    return (
        <div className="prompt-container">
            <div className="prompt-container-body">
                <div className="row">
                    <div className="col text-center">
                        <img
                            src={image}
                            alt="Install PWA"
                            className="prompt-container-body-logo"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="">Install this application on your homescreen for a better experience.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>
                            Tap
                            <img
                                src={share}
                                className="prompt-container-body-icon"
                                alt="Add to homescreen"
                                height="20"
                                width="20"
                            />
                            then &quot;Add to Home Screen&quot;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;