import React, {useState} from "react";
import "../../Assets/Style/styles.scss";
import VideoPlayer from '../Video'
import videojs from 'video.js';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Main = props => {
    let tocs = props.content;
    let subtitles = [];
    console.log(tocs)
    if(tocs.length > 0 && tocs[0].captions) {
        subtitles.push({
          language: 'en',
          url: tocs[0].captions[0].location,
          label: "English"
        })
    }
    let chapters = props.chapters;
    let id = props.state.chapterId.split("_")[1];
    let title = props.chapters && props.chapters[props.state.key] ? chapters[props.state.key].title : null;
    let sectionTitle = chapters ? chapters[props.state.key].sections[eval(id) -1].title : null;
    let count = props.count;
    let source = props.content && props.content[eval(id)-1] ? props.content[eval(id) -1].content : null;
    let currentChapter = chapters ? chapters[props.state.key] : null;
    let currentChapterId = currentChapter ? currentChapter.id : null;
    let nextSection = currentChapter ? currentChapter.sections[eval(id)] : null;
    let nextSectionId = nextSection ? nextSection.id : null;
    let previousSection = currentChapter ? currentChapter.sections[eval(id) -2] : null;
    let previousSectionId = previousSection ? previousSection.id : null;
    let previousChapter = chapters ? chapters[eval(props.state.key) -1] : null
    let previousChapterId = previousChapter ? previousChapter.id : null;
    let nextChapter = chapters ? chapters[eval(props.state.key) + 1] : null;
    let nextChapterId = nextChapter ? nextChapter.id : null;
    let nextChapterSectionId = nextChapter ? nextChapter.sections[0].id : null;
    let previousChapterSectionId = previousChapter ? previousChapter.sections[0].id : null;

    let forwards = nextSection ? currentChapterId + "/" + nextSectionId : nextChapterId + "/" + nextChapterSectionId;
    let backwards = previousSection ? currentChapterId +"/" +previousSectionId : previousChapterId +"/" + previousChapterSectionId;
    let navTitle = nextSection ? 'Next Section ' + (eval(id) + 1) : 'Next Chapter';
    const videoJsOptions = {
      autoplay: true,
      playbackRates: [0.5, 1, 1.25, 1.5, 2],
      controls: true,
      aspectRatio: '16:9',
      responsive: true,
      sources: [
        {
          src: source,
          type: 'video/mp4',
        },
      ],
      subtitles: subtitles,
    };
    console.log(videoJsOptions);
    return (
        <>
            {tocs &&
                <div className="row">
                    <div className="col">
                        <div className="book-content">
                            <h3>Chapter {(eval(props.state.key) + 1)}</h3>
                            <h2>{title}</h2>
                            {source && <VideoPlayer options={videoJsOptions}/>}
                            <h3>Section {id}</h3>
                            <h2>{sectionTitle}</h2>
                        </div>
                    </div>
                </div>
            }
        <hr/>
        <div className="row mt-5 p-4">
            <div className="col-3 text-center">
                {(previousSection || previousChapter) &&
                <Link className="btn primary-btn previous"  to={"/book/" + props.state.category+ "/" + props.state.isbn + "/" + backwards}>
                    <FontAwesomeIcon icon={faChevronLeft} className="fa-lg"/>
                </Link>
                }
            </div>
            <div className="col-6 text-center">
                {props.state.key <= (count - 1) &&
                <>
                    {navTitle}
                </>
                }
            </div>
            <div className="col-3 text-center">
                {(nextSection || nextChapter) &&
                    <Link className="btn primary-btn next" to={"/book/"+ props.state.category+ "/"  + props.state.isbn + "/" + forwards}>
                        <FontAwesomeIcon icon={faChevronRight} className="fa-lg"/>
                    </Link>
                }
            </div>
        </div>
       </>
    )
}

export default Main;