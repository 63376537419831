import { applyMiddleware, createStore } from "redux";
import { createLogger } from 'redux-logger';
import createSagaMiddleware from "redux-saga";
import rootReducer from "../Reducers";
import sagas from "../Sagas";


const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();

const store = createStore( rootReducer,
    applyMiddleware(
      sagaMiddleware,
      logger
  ))

export default store;

sagaMiddleware.run(sagas)


