import React from "react";
import {StarRating} from "../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const Main = props => {
    let titles = props.titles ?? null;
    const history = useHistory();

    function deleteHandler(isbn) {
        props.delete(isbn)
    }
    return(
        <div className="row">
            <div className="col-12">
                <div className="p-4 py-1s container-fluid">
                    <h5 className="text-orange">Download Books</h5>
                    <hr/>
                    {!titles &&
                        <>
                            <h4>No Saved books</h4>
                        </>
                    }
                    {titles && Object.keys(titles).map((key, val) => {
                        return (
                            <>
                                <FontAwesomeIcon icon={faTrash} size="lg" className="mr-2 mt-4 float-right" onClick={() => deleteHandler(key)}/>
                                <div  onClick={() => {history.push(titles[key].link)}} className="single-title mt-5">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-2 col-4">
                                            <img src={titles[key].data.coverImage} className="single-title-image"/>
                                        </div>
                                        <div className="col-lg-10  col-md-10 col-8 title">
                                            <h5>{titles[key].data.title}</h5>
                                            <h6>
                                                <small>
                                                    <StarRating />
                                                </small>
                                            </h6>
                                        </div>
                                        <div className="col-12 desc">
                                            <h6>{titles[key].data.oneLiner}</h6>
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>

    );
}

export default Main;