import React from "react";
import "../../Assets/Style/styles.scss";
import banner from "../../Assets/Images/banner.png";

const Main = props => {
   return (
    <div className="col-12">
        <img src={banner} className="width-100" />
    </div>
  );
}

export default Main;