import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFile, faSignInAlt, faBars } from "@fortawesome/free-solid-svg-icons";

const Main = props => {
  return (
    <>
      <div className="col-lg-0 col-md-3 col-sm-3 col-3 tile" onClick={() => props.setIsSearch()}>
        <a>
          <FontAwesomeIcon className="col-lg-0" icon={faSearch} /> 
          <span className="col-sm-0">Browse All</span>
        </a>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-3 col-3 tile">
        <a>
          <FontAwesomeIcon className="col-lg-0" icon={faFile} /> 
          <span className="col-sm-0">Free Learning</span>
        </a>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-3 col-3 tile">
        <a>
          <FontAwesomeIcon className="col-lg-0" icon={faSignInAlt} /> 
          <span className="col-sm-0" onClick={() => props.toggleModal('loginModal')}>Sign In</span>
        </a>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-3 col-3 tile" onClick={() => props.toggleModal('navModal')}>
        <a>
          <FontAwesomeIcon icon={faBars} /> 
        </a>
      </div>
    </>
  );
};

export default Main;