export default {
    base_url: "https://api.packt.com/"
  };

export const CURRENCY_SYMBOL = "₹";
export const DEFAULT_IMG = "../../Assets/logo.jpg";
export const DEFAULT_PATH = ""; //demo
export const LIMIT = 24; //demo
export const MAX_VALUES_PER_FACETS = 100; //demo
export const BASE_URL = "https://api.packt.com/"; //demo
export const API_URL = "https://subscription.packtpub.com/api/"; //demo
export const PRODUCT_URL = "https://static.packt-cdn.com/products/"; //demo
export const TOKEN = "q0RXUl1L9hdctYohDKq0LRn11Otq1ezO07jtL0ay"; //demoasdf
export const ALGOLIA_API_KEY = "945b46c99f0be80981c40d1fb3c7db74"; //demoasdf
export const MIX_RECAPTCHA_PUBLIC_KEY = "6LeAHSgUAAAAAKsn5jo6RUSTLVxGNYyuvUcLMe0_"; //demoasdf