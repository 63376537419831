import React from "react";
import "../../Assets/Style/styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const Main = props => {
  return (
    <div className="container nav-bar">
      {(!props.tokens || !props.tokens.loggedIn) && <div className="row pointer margin-top-15">
        <div className="col-12 text-center" onClick={() => props.toggleModal('loginModal')}>
            <button className="secondary-btn width-100">Sign in</button>
        </div>
        </div>}
        {(!props.tokens || !props.tokens.loggedIn) && <div className="row pointer">
          <div className="col-12 text-center">
              <Link className="btn primary-btn green"
                    to={{ pathname: "https://subscription.packtpub.com/register"}} target="_blank" >Sign Up</Link>
            <hr />
          </div>
        </div>}
        <div className="row pointer mt-5">
            <Link to={'/'} className="col-12 text-gray">
                Browse Library
            </Link>
            <div className="col-4">
            </div>
        </div>
      <div className="row pointer">
        <Link to={'/home'} className="col-12 text-gray">
            Recommended Titles
        </Link>
      </div>
        <div className="row pointer">
         <Link to={'/expert-reading-lists'} className="col-8 text-gray">
            Expert Reading Lists
        </Link>
        <div className="col-4"></div>
        </div>
       <div className="row pointer">
            <Link to={'/download-books'} className="col-8 text-gray">
                Downloaded Books
            </Link>
            <div className="col-4"></div>
        </div>
        {(!props.tokens.loggedIn) &&
            <div className="row">
                <div className="col-12 ">
                    <Link className="btn primary-btn "
                          to={{ pathname: "https://www.packtpub.com/checkout/subscription/packt-subscription-monthly-launch-offer?freeTrial"}} target="_blank" >Start Free Trial</Link>
                </div>
            </div>
        }
    </div> 
  )
}

export default Main;