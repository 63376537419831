import React from "react";
import "../../Assets/Style/styles.scss";
import { Input, FormGroup } from "reactstrap";
import { keyPressEnter } from "../../Helpers";
import styles from "../../Assets/Style/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Main = props => {
   return (
      <FormGroup className={props.className}>
         <Input
              type="text"
              className="search-input"
              style={styles.searchBackground}
              autoComplete="off"
              value={props.keywords}
              placeholder="Search on Packt..."
              autoFocus={true}
              onChange={(e) => props.setFilters("keywords", e.target.value)}
         />
         <FontAwesomeIcon icon={faTimes} className="search-close-icon" onClick={() => props.keywords ? props.setFilters("keywords", "") : props.handleClose()} />
      </FormGroup>
   )
};

export default Main;