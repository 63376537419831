import React, {useEffect, useState} from "react";
import "../../Assets/Style/styles.scss";
import logo from "../../Assets/Images/logo.png";
import { SearchBar, UserNav, SearchResult, Helpers } from "../../Components";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserCircle, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "../../Assets/Style/";

const Main = props => {
    const logoClickHandler = () => {
      props.setUserNav(false);
    }
    return (
      <div className="header" style={styles.header}>
        <div className="container-fluid">
        <div className="row">
          <Link to={"/"} className="col-lg-10 col-md-9 col-sm-9 col-7">
            <img src={logo} className="logo" onClick={() => logoClickHandler()} />
          </Link>
           <div className="col-lg-2 col-md-3 col-sm-3 col-5">
            <div className="row">
              <div className={((!props.tokens || !props.tokens.loggedIn) ? "offset-4 " : "") + "offset-2 col-3 col-sm-3 col-lg-3 col-md-3 text-right"}>
                {!props.showSearchBar && !props.overrideSearchShow && <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={() => props.setShowSearchBar()} />}
                 {!props.overrideSearchShow && <SearchBar keywords={props.keywords} setFilters={(key, val) => props.setFilters(key, val)} className={props.showSearchBar ? "show" : "hide"} handleClose={() => props.handleClose()} handleChangeKeywords={(e) => props.handleChangeKeywords(e)} />}
              </div>
              {(props.tokens && props.tokens.loggedIn) && <div className="col-3 col-sm-3 col-lg-3 col-md-3 text-right">
                <FontAwesomeIcon icon={faUserCircle} className="user-icon" onClick={() => props.setUserNav(!props.showUserNav)} />
                <UserNav signOut={() => props.signOut()} className={props.showUserNav ? "show user-nav" : "hide"} />
              </div>}
              <div className={"col-3 col-sm-3 col-lg-3 col-md-3 text-right"}>
                {!props.navModal && <FontAwesomeIcon icon={faBars} className="nav-icon" onClick={() => props.toggleModal('navModal', true)} />}
                {props.navModal && <FontAwesomeIcon icon={faTimes} className="nav-icon" onClick={() => props.toggleModal('navModal', false)} />}
              </div>
            </div>
        </div>
        </div>
        </div>
      </div>
    );
}

export default Main;