import {Component} from "react";

class Storage extends Component {
    constructor(props) {
        super(props);
    }

    saveBook(contentArray, isbn, link, bookData){
        try {
            let books = JSON.parse(localStorage.getItem('books')) ?? {};
            if(!books[isbn]){
                books[isbn] = {};
            }
            contentArray.forEach((value,key) => {
                if(!books[isbn]['content']){
                    books[isbn]['content'] = {};
                }
                if(!books[isbn]['content'][key]){
                    books[isbn]['content'][key] = {};
                }
                books[isbn]['content'][key] = {...value}
            });
            books[isbn]['link'] = link;
            books[isbn]['data'] = {...bookData}
            localStorage.setItem('books', JSON.stringify(books));
            return true;
        } catch (error){
            console.log(error);
            return false
        }
    }

    removeBook(isbn){
        let books = JSON.parse(localStorage.getItem('books'));
        delete books[isbn];
        localStorage.setItem('books', JSON.stringify(books));
        return books;
    }

    getBook(isbn){
        let books = JSON.parse(localStorage.getItem('books'));
        return books[isbn];
    }

    getAllBooks(){
        return JSON.parse(localStorage.getItem('books'));
    }

}

export default Storage;