import React from "react";
import "../../Assets/Style/styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StarRating } from "../../Components";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { createBrowserHistory } from 'history';
import {Link} from "react-router-dom";
import Loading from '../Shared/loader'
export const history = createBrowserHistory();

const Main = props => {
   var listArr = [];
   return (
      <>
      {Object.keys(props.selectedItem).length == 0 && <Loading
           loading={true}
       />}
      {Object.keys(props.selectedItem).length > 0 && <div className="container expert-details margin-bottom-50">
      <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={() => props.handleClose()} />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h3>{props.selectedItem.title}</h3>
            <h6 className="text-gray">{props.selectedItem.name}</h6>
            <h6 className="text-gray">{props.selectedItem.curation_date}</h6>
            <hr />
          </div>
          {props.selectedItem.products.forEach((val) => {
            if(val.isbns && val.isbns.print) {
              listArr.push(<Link className="col-11 offset-1 expert-books" to={"product" + val.shopUrl + "/" + val.isbns.print}>
              <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                    <img src={val.smallImage} className="expert-book-image" />
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 expert-book-detail">
                    <h5 className="text-blue">{val.title}</h5>
                    <h6 className="text-gray">{val.oneLiner}</h6>
                    <h6 className="text-gray"><small>By {val.linkAuthors.length == 1 ? val.linkAuthors[0].author : val.linkAuthors[0].author + " and " + val.linkAuthors.length - 1 + " more"}</small></h6>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-12 col-12 expert-books-right-panel">
                    {/**<StarRating color={"#F6B11F"} rating={5} />**/}
                    <br />
                    {val.publicationDate && <h6 className="text-gray"><small>Published in {val.publicationDate.split("-")[0]}</small></h6>}
                    <br />
                    <h6 className="text-gray">{val.pages} Pages</h6>
                  </div>
              </div>
            </Link>);
            }
          })}
          {listArr}
        </div>
      </div>}
      </>
   )
};

export default Main;
