import React from "react";
import "../../Assets/Style/styles.scss";
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

const Main = props => {
  return (
    <div className="container margin-top-100 text-right">
      <div className="row">
        <div className="col-12 left-nav-tab" onClick={() => props.toggleModal('loginModal')}>
            Login
        </div>
        <div onClick={() =>  props.toggleModal('registerModal')} className="col-12 left-nav-tab">
            Register
        </div>
        <div onClick={() => history.push('home')} className="col-12 left-nav-tab">
           Home
        </div>
         <div onClick={() => history.push('expert-reading-lists')} className="col-12 left-nav-tab">
            Expert Reading
        </div>
        <div className="col-12 left-nav-tab">
            Free Trial
        </div>
      </div>
    </div> 
  )
}

export default Main;