import React, {useState, useRef} from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import {doLogin} from '../../Services/services';
import "../../Assets/Style/styles.scss";
import logo from "../../Assets/Images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

const Main = props => {
  const [useremail, setUserEmail] = useState("");
  const [useremailError, setUserEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [tokens, setTokens] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const doLoginHandler = () => {
    let error = false;
    setPasswordError("");
    setUserEmailError("");
    setErrorMessage("");
    if(!password) {
      error = true;
      setPasswordError("*Field is required");      
    }
    if(!useremail) {
      error = true;
      setUserEmailError("*Field is required");      
    }
    if(!error) {
      props.setMask(true);
      let payload = {password: password, username: useremail}
      doLogin(doLoginCallback, payload)
    }
  }

  const doLoginCallback = (response) => {
    if(response && response.data) {
      let token = {};
      token.refresh_token = response.data.refresh;
      token.access_token = response.data.access;
      token.loggedIn = true;
      props.setTokens(token);
      props.getSubscription();
    } else {
      setErrorMessage(response.message);
      props.setMask(false);
    }
  }


  return (
    <div className="container login-panel">
      <FontAwesomeIcon className="close-icon" icon={faTimes} onClick={() => props.handleClose()} />
      <div className="row">
        <div className="col-12 text-center">
          <img src={logo} className="logo" />
          <h6><small><a>Find out more</a></small></h6>
          <hr />
        </div>
        <div className="col-12">
          {errorMessage && <h6 className="error text-center"><small>{errorMessage}</small></h6>}
          <div className="form-group">
            <input name="email" type="email" id="login-input-email" value={useremail} onChange={(e) => setUserEmail(e.target.value)} className="form-control" placeholder="Email address" required="" autofocus="" />
            {useremailError && <span className="error"><small>{useremailError}</small></span>}
          </div>
          <div className="form-group">
           <input name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}  id="login-input-password" class="form-control mb20" placeholder="Password" required="" />
            {passwordError && <span className="error"><small>{passwordError}</small></span>}
          </div>
          <div className="form-group">
            <button className="primary-btn green" type="submit" onClick={() => doLoginHandler()}> <span>Sign In</span> </button>
          </div>
        </div>
        {/**<div className="col-12">
          <small><a href="#"> Forgotten your password? </a></small>
          <hr />
        </div>
        <div className="col-12 text-center">
          <small><a onClick={() =>  history.push('register')}> Create Account </a></small>
        </div>**/}
      </div>
    </div>
  )
}

export default Main;