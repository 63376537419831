import React from "react";
import "../../Assets/Style/styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { createBrowserHistory } from 'history';
import {Link} from "react-router-dom";
import Loading from '../Shared/loader'
import { TocContainer, StarRating } from "../../Components";
export const history = createBrowserHistory();

const Main = props => {
    let isbn = null;
    let category = null;
    let chapter = null;
    let displayPurchaseOptions = true;
    if(props.selectedItem) {
      isbn = props.selectedItem.productId ?? props.selectedItem.print_isbn13;
      category = props.selectedItem.category.replace(/\s+/g, '-').toLowerCase() ?? null;
      chapter = props.selectedItem.type === 'videos' ? 'p1/video1_1' : "1/ch01lvl1sec01";
    }
    if(props.subscription && props.subscription.subscriptionStatus == "active" && !props.subscription.freeTrial) {
      displayPurchaseOptions = false;
    }

   return (
      <>
      {props.selectedItem && 
      <div className="item-details">
        <div className="row item-details-header">
          <div className="container mt-5 pt-4">
            <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12 col-12 pt-5">
              <div className="row">
                <div className="col-lg-2 col-md-4 col-sm-4 col-4">
                  <img className="item-details-img" src={props.selectedItem.smallImage} />
                </div>
                <div className="col-lg-10 col-md-8 col-sm-8 col-8">
                  <h4 className="item-details-title">{props.selectedItem.title}</h4>
                  <h6 className="text-white"><small>{props.selectedItem.oneLiner}</small></h6>
                  <h6 className="text-white"><small>By {props.selectedItem.linkAuthors[0].author + (props.selectedItem.linkAuthors[0].length > 1 ? (props.selectedItem.linkAuthors[0].length - 1) + " and more" : "")}</small></h6>
                  {/**<div className="display-flex">
                      <StarRating  color={"#FFF"} rating={props.selectedItem.reviews.rating} />
                      <h6 className="text-white pt-1 pl-1"><small> {props.selectedItem.reviews.rating} {"(" + props.selectedItem.reviews.count + " reviews)"}</small></h6>
                    </div>**/}
                </div>
                {props.selectedItem.type == "books" && displayPurchaseOptions && <div className="col-sm-12 col-12 col-lg-12 col-md-12 margin-top-15">
                  <div className="row item-details-purchase">
                    <div className="col-3">FREE</div>  
                    <div className="col-4">Subscribe</div>  
                    <div className="col-5 text-right"><Link to={"/book/"+ category + "/" + isbn + "/" + chapter +""} className="text-blue">Access Now</Link></div>    
                  </div>
                  <div className="row item-details-purchase">
                    <div className="col-3">{props.selectedItem.price.print.formattedPrice}</div>  
                    <div className="col-4">Print</div>  
                    <div className="col-5 text-right"><a target="_blank" href={props.selectedItem.price.print.buyUrl} className="text-blue">Buy</a></div>   
                  </div>
                  <div className="row item-details-purchase">
                    <div className="col-3">{props.selectedItem.price.ebook.formattedPrice}</div>  
                    <div className="col-4">Ebook</div>  
                    <div className="col-5 text-right"><a target="_blank" href={props.selectedItem.price.ebook.buyUrl} className="text-blue">Buy</a></div>
                  </div>
                </div>}
                <div className="col-12 margin-top-15 margin-bottom-20">
                  {(!props.tokens || !props.tokens.loggedIn) && <button onClick={() => props.toggleModal("loginModal")} className="secondary-btn width-100">Sign In</button>}
                  {(props.tokens && props.tokens.loggedIn) && 
                      <Link to={"/book/"+ category + "/" + isbn + "/" + chapter +""}>
                        <button className="secondary-btn width-100">{props.selectedItem.type == "books" ? "Read" : "Watch Video"}</button>
                      </Link>
                  }
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12 reader-navigation-chapters">
               <TocContainer
                    toc={props.selectedItem.toc}
                    state={props.state}
                />
            </div>
          </div>
          </div>
        </div>
        <div className="container margin-top-50">
          <div className="row item-details-desc">
            <div className="col-12 col-sm-12 col-lg-8 col-md-8">
              <h4>About this {props.selectedItem.type == "books" ? "book": "video"}</h4>
              <div dangerouslySetInnerHTML={{__html: props.selectedItem.about }} />
            </div>
            <div className="col-12 col-sm-12 col-lg-4 col-md-4 margin-top-15">
              <h6>Publication date:</h6>
              <h6><small>{props.selectedItem.publicationDate.split("T")[0]}</small></h6>
              <hr />
              <h6>Publisher:</h6>
              <h6><small>{props.selectedItem.publisher}</small></h6>
              <hr />
              <h6>Pages:</h6>
              <h6><small>{props.selectedItem.pages}</small></h6>
              <hr />
              <h6>ISBN:</h6>
              <h6><small>{props.selectedItem.isbn13}</small></h6>
              <hr />
            </div>
        </div>
      </div>
      </div>}
      {!props.selectedItem && <Loading
           loading={true}
       />}
      </>
   )
};

export default Main;
