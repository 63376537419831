import React from "react";
import "../../Assets/Style/styles.scss";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

const Main = props => {
    let tocs = props.content ?? null
    let content = tocs ? tocs[Object.keys(tocs)[props.state.key]] : null;
    let count = props.count;
    return (
        <>
            {content &&
                <>
                    <div className="row">
                        <div className="col">
                            <div className="book-content fade-out">
                                <div dangerouslySetInnerHTML={{__html: content.content}}/>
                            </div>
                        </div>
                    </div>
                    <div className="upsell-box">
                        <div className="reader-upsell-container">
                            <div className="upsell-title">Unlock full access</div>
                            <h2>Continue reading with a FREE trial</h2>
                            <h4>Packt gives you instant online access to a library of over 7,500 practical eBooks and videos, constantly updated with the latest in tech</h4>
                            <div className="btn-parent">
                                <Link to={"https://www.packtpub.com/checkout/subscription/packt-subscription-monthly-launch-offer?freeTrial" } className="secondary-btn">
                                    Start FREE 10-day trial
                                </Link>
                                <div className="sign-in">
                                    <Link to={'/login'}>Or Sign In</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                 </>
            }
            <hr/>
            <div className="row mt-5 p-4">
                <div className="col-3 text-center">
                    {props.state.key > 1 &&
                        <Link className="btn primary-btn previous"  to={
                            "/book/"+props.state.category + "/" + props.state.isbn + "/" + eval(props.state.key) + "/"  + props.previousChapter
                        }>
                            <FontAwesomeIcon icon={faChevronLeft} className="fa-lg"/>
                        </Link>
                    }
                </div>
                <div className="col-6 text-center">
                    {props.state.key <= (count - 1) &&
                        <>
                            End of Chapter {(props.state.key + 1)}
                        </>
                    }
                </div>
                <div className="col-3 text-center">
                    {props.state.key <= (count -1) &&
                        <Link className="btn primary-btn next" to={
                            "/book/"+props.state.category + "/" + props.state.isbn + "/" + (eval(props.state.key) + 2) + "/" + props.nextChapter
                        }
                        >
                            <FontAwesomeIcon icon={faChevronRight} className="fa-lg"/>
                        </Link>
                    }
                </div>
            </div>
        </>
    )
}

export default Main;