import { createBrowserHistory } from "history";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./Containers/Dashboard";

const AppRouter = () => (
  <Router history={createBrowserHistory}>
    <div>
      <Switch>
        <Route exact path='/' component={Dashboard} />
        <Route exact path='/login' component={Dashboard} />
        <Route exact path='/home' component={Dashboard} />
        <Route exact path='/product/:category/:book/:isbn' component={Dashboard} />
        <Route exact path='/expert-reading-lists' component={Dashboard} />
        <Route exact path='/register' component={Dashboard} />
        <Route exact path='/search' component={Dashboard} />
        <Route exact path='/download-books' component={Dashboard} />
        <Route exact path='/book/:category/:isbn/:chapter/:section/:title?' component={Dashboard} />
      </Switch>
    </div>
  </Router>
);
export default AppRouter;
