import {API_URL, ALGOLIA_API_KEY, LIMIT, MAX_VALUES_PER_FACETS} from '../Config';
import { fsWrite, fsRead } from "../Helpers";

export function getBooks(callback, offset = 0, keywords="", selectedFiltersList = "",  sortBy = "subs_search_prod", perPage = LIMIT) {
    selectedFiltersList = decodeURI(selectedFiltersList);
    var selectedFiltersListArr = [];
    var filters = [];
    var facetFilters = [];
    var facetFiltersObj = {
        "published_year": [],
        "concept": [],
        "category": [],
        "imprint": [],
        "primary_tool": [],
        "primary_language": [],
        "released": []
    }
    if(selectedFiltersList) {
        var filterJSON = selectedFiltersList.split(" AND ");
        filterJSON.forEach((val) => {
            if(val) {
                var json2 = val.split(" OR ");
                json2.forEach((val2) => {
                    let val3 = val2.split(":");
                    if(val3[0].trim()) {
                        let jsonObj = {};
                        jsonObj[val3[0]] = val3[1];
                        selectedFiltersListArr.push(jsonObj);
                        if(val3[0] == "product_type") {
                            filters.push("product_type:'" + val3[1].replace("-", "%26") + "'")
                        } else {
                            facetFiltersObj[val3[0]].push(val3[0] + ":" + val3[1].replace("-", "%26"));
                        }
                    }
                });
            }
        });
        Object.keys(facetFiltersObj).forEach((val2) => {
            if(facetFiltersObj[val2].length > 0) {
                facetFilters.push(facetFiltersObj[val2]);
            }
        });
    }
    var url = "https://vivzzxfqg1-3.algolianet.com/1/indexes/*/queries?x-algolia-agent=Algolia%20for%20vanilla%20JavaScript%20(lite)%203.27.0%3Binstantsearch.js%202.7.4%3BJS%20Helper%202.26.0&x-algolia-application-id=VIVZZXFQG1&x-algolia-api-key=" + ALGOLIA_API_KEY;
    var quaryParam = {"requests": []};
    var searchParams = {"indexName": sortBy, "params": "query=" + keywords +
     "&hitsPerPage=" + perPage +
     "&maxValuesPerFacet=" + MAX_VALUES_PER_FACETS +
     '&attributesToHighlight=["*"]' +
     "&page=" + parseInt(offset - 1) + 
     "&filters=" + filters.join(" OR ") +
     "&facetFilters=" + JSON.stringify(facetFilters) + 
     "&ruleContexts=['subscriptions']" +
     '&facets=["product_type","category","imprint","published_year","concept","primary_tool","primary_language","released"]'};
   quaryParam.requests.push(searchParams);
   fetch(url, {
       method: 'POST',
       body: JSON.stringify(quaryParam),
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
    if(!keywords && !selectedFiltersList) {
        fsWrite('searchPage.json', JSON.stringify(res));
    }
    callback(res);
   })
   .catch(res => {
    fsRead('searchPage.json', callback);
   });
}

export function getChapterContent(callback, isbn, chapterId, chapters){
    let promises = []
    chapters.forEach((sectionId, key) => {
        promises.push(fetch(
            "https://subscription.packtpub.com/api/product/content/mobile/" + isbn + "/" + chapterId + "/" + sectionId

        ))
    });
    Promise.all(promises).then(function (responses) {
        return Promise.all(responses.map(function (response) {
            return response.json();
        }));
    }).then(function (data) {
       callback(data);
    });
}

export function getAllChapterContent(callback, isbn, data){
    let promises = []
    let token = JSON.parse(localStorage.getItem("tokens"));
    let authorisation = token ? token.access_token : null;
    data.forEach((section, key) => {
        section.forEach((chapter, index) => {
            promises.push(fetch(
                "https://subscription.packtpub.com/api/product/content/mobile/" + isbn + "/" + (eval(key) +1)+ "/" + chapter, {
                    headers : {
                        "Authorization": "Bearer " + authorisation
                    }
                }
            ))
        });
    });
    Promise.all(promises).then(function (responses) {
        return Promise.all(responses.map(function (response) {
            return response.json();
        }));
    }).then(function (data) {
        callback(data);
    });
}


export function getVideoContent(callback, isbn, chapterId, chapters){
    let token = JSON.parse(localStorage.getItem("tokens"));
    let authorisation = token ? token.access_token : null;
    let promises = []
    chapters.forEach((sectionId, key) => {
        promises.push(fetch(
            "https://subscription.packtpub.com/api/products/" + isbn + "/" + chapterId + "/" + sectionId, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authorisation
                }
            }
        ))
    });
    Promise.all(promises).then(function (responses) {
        return Promise.all(responses.map(function (response) {
            return response.json();
        }));
    }).then(function (data) {
        callback(data);
    });
}



export function getRecommendTitles(callback, offset = 1, keywords="",  sortBy = "latest") {
  var url = API_URL + "user/suggested-titles";
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     fsWrite('recommendTitles.json', JSON.stringify(res));
     callback(res);
   })
   .catch(res => {
    fsRead('recommendTitles.json', callback);
   });
}

export function getNewReleases(callback, offset = 1, keywords="",  sortBy = "latest") {
  var url = API_URL + "packt/new-releases";
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     fsWrite('newRelease.json', JSON.stringify(res));
     callback(res);
   })
   .catch(res => {
    fsRead('newRelease.json', callback);
   });
}

export function getTrendingTitles(callback, offset = 1, keywords="",  sortBy = "latest") {
  var url = API_URL + "packt/trending-titles";
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     fsWrite('trendingTitles.json', JSON.stringify(res));
     callback(res);
   })
   .catch(res => {
    fsRead('trendingTitles.json', callback);
   });
}

export function getRecentItems(callback, offset = 1, keywords="",  sortBy = "latest") {
  var url = API_URL + "subscription/getrecentitems";
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     fsWrite('recentTitles.json', JSON.stringify(res));
     callback(res);
   })
   .catch(res => {
    fsRead('recentTitles.json', callback);
   });
}

export function trackReader(callback, productId, sectionId, timeSpent){

    let promises = [];
    // disable rum and intercom for now but leaving code incase we need this back
    // promises.push(fetch('https://subscription.packtpub.com/cdn-cgi/rum?'));
    // promises.push(fetch('https://api-iam.intercom.io/messenger/web/ping'));

    promises.push(
        fetch('https://subscription.packtpub.com/api/users/me/completed',
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    productId: productId,
                    sectionId: sectionId,
                    timeSpent: timeSpent
                })
            }
        )
    );

    Promise.all(promises).then(function (responses) {
        return Promise.all(responses.map(function (response) {
            return response.json();
        }));
    }).then(function (data) {
        callback(data);
    }).catch(function (error) {
        callback(error);
    });
}

export function getTitleDetails(callback, isbn) {
  var url = API_URL + "products/" + isbn + "/summary";
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     callback(res);
   });
}

export function getExpertReaders(callback, param) {
  var url = API_URL + "expertreaders/url/" + param;
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     callback(res);
   });
}

export function getExpertsContentful(callback) {
  var url = API_URL + "expertreaders/contentful";
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     callback(res);
   });
}

export function getExperts(callback, limit = 4) {
  if(limit != "all") {
    limit = "homepage/" + limit;
  }
  var url = API_URL + "expertreaders/" + limit;
  fetch(url, {
       method: 'GET',
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     callback(res);
   });
}

export function doLogin(callback, payload) {
  var url = "https://services.packtpub.com/auth-v1/users/tokens";
  fetch(url, {
       method: 'POST',
       body: JSON.stringify(payload),
       headers:{
           "Content-Type": "application/json"
       }
   }).then(res => res.json())
   .then(res=>{
     callback(res);
   });
}


export function getSubscription(callback, payload) {
    if(localStorage.getItem("tokens")) {
        var token = JSON.parse(localStorage.getItem("tokens"));
      var url = API_URL + "users/me/subscriptions";
      fetch(url, {
           method: 'GET',
           headers:{
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token.access_token
           }
       }).then(res => res.json())
       .then(res=>{
         callback(res);
       }).catch((error) => {
          console.log(error)
      });
    }
}

export function verifyTokens(callback) {
    if(localStorage.getItem("tokens")) {
      var token = JSON.parse(localStorage.getItem("tokens"));
      var payload = {"refresh": token.refresh_token};
      var url = API_URL + "users/me/tokens";
      fetch(url, {
           method: 'POST',
           body: JSON.stringify(payload),
           headers:{
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token.access_token
           }
       }).then(res => res.json())
       .then(res=>{
         callback(res);
       }).catch((error) => {
          console.log(error)
      });
    }
}