import {history} from "../Utils/history";
var fs = require('browserify-fs');

/*** Key Press Enter ***/
export const keyPressEnter = (e, props) => {
	if(e.keyCode == 13){
	 	props.getBooks(props.keywords);
	}
}

/** onclick filters **/
export const handleOnClick = val => {
	history.push("/search?keyword=" + encodeURI(val));
}

/** item toggle **/
export const itemToggle = val => {
    if(document.getElementById("collapse-" + val).style.display != 'none') {
      document.getElementById("collapse-" + val).style.display='none';
    } else {
      document.getElementById("collapse-" + val).style.display='block';
    }
}

/** file write **/
export const fsWrite = (file, data) => {
	fs.mkdir('/tmp', function() {
     fs.writeFile('/tmp/' + file, JSON.stringify(data), function() {
       
     });
   });
}


/** file read **/
export const fsRead = (file, callback) => {
	fs.readFile('/tmp/' + file, 'utf-8', function(err, data) {
		callback(JSON.parse(JSON.parse(data)));
	});
}