import * as React from "react";
import { useRef, useEffect, memo } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
interface componentProps {
  options : videojs.PlayerOptions;
  onReady : (VideoJsPlayer) => void
}
export const VideoJS = ({options, onReady} : componentProps) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      });
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <video style={{width: "100%"}} ref={videoRef} className="video-js vjs-big-play-centered" />
  );
}

export default memo(VideoJS)
