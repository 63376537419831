import React, { useState } from "react";
import { StarRating } from "../../Components";
import {PRODUCT_URL} from '../../Config';
import {Link} from "react-router-dom";
import * as moment from 'moment';
const Main = props => {
  let keywords = props.keywords.toLowerCase();
  let listArr = [];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {
            props.list.forEach((val) => {
                listArr.push(<Link to={'/product' + val.seoUrl + "/" + val.print_isbn13} key={"product-" + val.objectID} className={"col-lg-4 col-md-6 col-sm-12 col-12 reading-list-media products media"}>
                  <div className="img-list list-size-3">
                    <img className="img-0" src={PRODUCT_URL + val.print_isbn13 + "/cover/smaller"} />
                  </div>
                  <div className={"media-body"}>
                  {val.early_access && <div className="early-eccess">Early Access</div>}
                  <h5 className="title">
                      {val.title}
                  </h5>
                    <div className="product-rating">
                      {/**<StarRating color={"#F6B11F"} rating={5} />*/}
                    </div>
                    <div className="product-desc">{val.description}</div>
                    {val.author && <div className="product-author">
                        By {val.author.join(", ")}
                    </div>}
                    <div className="row product-bottom">
                      <div className="col-6">
                          {moment(val.published_on).format('MMM YYYY')}
                      </div>
                      <div className="col-6 text-right">
                          {val.length}
                      </div>
                    </div>
                    </div>
                </Link>);
            })
          }
          {listArr}  
        </div>
      </div>
    </>
  );
}

export default Main;