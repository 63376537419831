import React, {useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { StarRating } from "../../Components";
import Loading from '../Shared/loader'
import {PRODUCT_URL} from '../../Config';
import {Link} from "react-router-dom";
import * as moment from 'moment';

 let listArr = [];

const Main = props => {
  const ref = useRef(null);
  let keywords = props.keywords.toLowerCase();
  
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div className="my-5">
    {!props.list.content && <Loading
           loading={true}
       />}
    {props.list.content && Object.keys(props.list.products).length > 0 && <div>
      <h4>{props.list.content.jsonValue.title}</h4>
      <h6 className="text-gray"><small>{props.list.content.jsonValue.body}</small></h6>
      <FontAwesomeIcon onClick={() => scroll((ref.current.offsetWidth * -1) - 10)} className="left-angle" icon={faAngleLeft} />
      <div className="titles-rows" ref={ref}>
        {props.list.products && Object.keys(props.list.products).forEach((key, val) => {
          listArr.push(<Link to={"product" + props.list.products[key].readUrl.replace("/book/", "/read/")} className="single-title">
            <div className="row">
              <div className="col-4">
                <img src={PRODUCT_URL + props.list.products[key].productId + "/cover/smaller"} />
              </div>
              <div className="col-8 title">
                <h5>{props.list.products[key].title}</h5>
                <h6 className="text-gray">
                  <small>
                    {/**<StarRating color={"#F6B11F"} rating={5} />*/}
                  </small>
                </h6>
              </div>
              <div className="col-12 desc text-gray">
                {props.list.products[key].oneLiner}
              </div>
            </div>
          </Link>);
        })}
        {listArr}
      </div>
        <FontAwesomeIcon onClick={() => scroll((ref.current.offsetWidth)+10)} className="right-angle" icon={faAngleRight} />
    </div>}
    </div>
  );
}

export default Main;