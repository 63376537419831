import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import { history } from "../../Utils/history";
import { SAVE_PRODUCT, TOGGLE_PRODUCT, GET_PRODUCT } from "../ActionTypes";
import { saveOrders, fetchInventory } from "../Actions";

function* getProduct(actions) {
  try {
    const editResponse = yield apiCall({
      method: "POST",
      url: config.report_api + `/${actions.id}`,
      data: actions.data
    });
    if (editResponse && editResponse.data && editResponse.data.status) {
      
    }
  } catch (err) {
    console.log(err);
  }
}



export default function* root() {
  yield takeEvery(GET_PRODUCT, getProduct);
}
