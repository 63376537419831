import React, { Component } from "react";
import { connect } from "react-redux";
import useIsIOS from "../../Helpers/IOSHelper"
import "../../Assets/Style/styles.scss";
import { Header, 
  Filters,
  Products, 
  HomeTitles,
  ItemDetails, 
  ExpertsDetails, 
  Login, 
  FilterBar, 
  Reader,
  ExpertReadingList, 
  Nav, 
  Pagination, 
  ReaderNavigation,
  InstallPWA,
} from "../../Components";
import {
  getBooks, 
  getVideoContent,
  trackReader, 
  getRecommendTitles, 
  getRecentItems, 
  getExpertReaders, 
  getNewReleases, 
  getTrendingTitles, 
  getTitleDetails, 
  getExpertsContentful, 
  getChapterContent,
  getAllChapterContent,
  getExperts,
  getSubscription,
  verifyTokens
} from '../../Services/services';

import Storage from "../../Utils/storage";
import styles from "../../Assets/Style/";
import {history} from "../../Utils/history";
import Modal from 'react-modal';
import loader from "../../Assets/Images/loader.gif";
import {MIX_RECAPTCHA_PUBLIC_KEY} from '../../Config';
import Recaptcha from 'react-google-invisible-recaptcha';
import { fsWrite, fsRead } from "../../Helpers";
import OfflineTitles from "../../Components/OfflineTitles";

class Main extends Component {
  constructor(props) {
    super();
    if(navigator.onLine){
      this.refRecaptcha = React.createRef();
    }
    this.prompt = useIsIOS();
    this.state = {
      storage : null,
      keywords: "",
      facets: {},
      pageNo: 1,
      products: [],
      homeItems: [],
      isSearch: false,
      showSearchBar: false,
      overrideHideSearch : false,
      showUserNav: false,
      error: "",
      hasNewKeyword: false,
      loginModal: false,
      registerModal: false,
      filtersModal: false,
      detailsModal: false,
      detailItem: "ITEM",
      selectedItem: null,
      navModal: false,
      hasPopup: false,
      displayExpertReadingList: false,
      closedFilters: {},
      currentPath: '/',
      previousPath: '/',
      isbn: null,
      titleSummary: null,
      mask: false,
      nbHits: 0,
      nbTotalHits: 0,
      nbPages: 0,
      selectedFiltersList: {},
      content: {
        expertReadings: ""
      },
      readerState : {
        isbn: null,
        section: null,
        chapterId: null,
        loading: false,
        type: null,
        lastTrackedId: null,
        completed: null,
        downloading :false,
        page: "reader",
        timeSpent: 0,
        timer : 0,
      },
      chapterCount : 0,
      downloaded : null,
      allChapters: [],
      showReaderNavigation: false,
      perPage: 24,
      sortBy: "subs_search_prod",
      experts: [],
      queryParam: [],
      tokens: {},
      recentItems: [],
      recommendTitles: [],
      newReleases: [],
      trendingTitles: [],
      stateUpdate: false,
      expertReadersObject: {},
      displayHeader: true,
      displayFilterBar: false,
      displayLeftButton: false,
      displayUpsellBanner: false,
      expertsLists: false,
      productsList: false,
      displayHomeTitles: false,
      productPage: false,
      readerPage: false,
      loader: false,
      subscription: {}
    };
  }

  componentDidMount() {
    this.setupStorage();
    if(!this.state.keywords) {
      this.getExpertsContentful();
      this.getRecentItems();
      this.getRecommendTitles();
      this.getNewReleases();
      this.getTrendingTitles();
    }
    if(localStorage.getItem("tokens")) {
      verifyTokens(this.verifyTokensCallback.bind(this));
    }
  }

  componentDidUpdate() {
    if(window.location.href != this.state.currentPath) {
      this.setState({detailsModal: false});
      this.setState({navModal: false});
      this.setState({loginModal: false});
      this.setState({registerModal: false});
      this.setState({previousPath: this.state.currentPath});
      this.setState({currentPath: window.location.href});
      this.setState({expertsLists: false});
      this.setState({productsList: false});
      this.setState({displayLeftButton: false});
      this.setState({displayUpsellBanner: false});
      this.setState({displayHomeTitles: false});
      this.setState({productPage: false});
      this.setState({readerPage: false});
      this.setState({loader: false});
      this.setState({displayFilterBar: false});
      this.setState({titleSummary: null});
      this.setState({downloaded : null});
      fsRead('searchPage.json', this.getBooksCallback.bind(this));

      var b = document.querySelector('body');
      b.classList.remove('reader');
      this.setState({overrideHideSearch : false});
      let keywords = "";
      let sortBy = "subs_search_prod";
      let perPage = 24;
      let pageNo = 1;
      let selectedFiltersList = {};
      let selectedFiltersListStr = "";

      try {
        if(window.location.pathname == "/" || window.location.pathname == "/home") {
          this.setState({displayHomeTitles: true}, () => {
            if(Object.keys(this.state.facets).length > 0) {
                this.getBooks(keywords, pageNo, selectedFiltersListStr, sortBy, perPage);
              }
              if(document.querySelector('.trending')) {
              document.querySelector('.trending').scrollTo(0, 0);
            }
          });
        }

        if(window.location.pathname.indexOf('/expert-reading-lists') > -1) {
          this.setState({expertsLists: true});
          this.setState({displayFilterBar: false});
          this.setState({mask: true});
          this.getExperts("all");
          if(Object.keys(this.state.facets).length > 0) {
            this.getBooks(keywords, pageNo, selectedFiltersListStr, sortBy, perPage);
          }
        } 
        
        if(window.location.search.indexOf("keywords=") > -1) {
          keywords = decodeURI(window.location.search.split("keywords=")[1]);
          keywords = keywords.split("&")[0];
          this.setState({showSearchBar: true});
        }

        if(window.location.pathname == "/") {
          this.setState({displayUpsellBanner: true});
        }

        if(window.location.pathname == "/" || window.location.pathname == "/search") {
          this.setState({mask: true});
          this.setState({displayLeftButton: true});
          this.getExperts(4);
          this.setState({displayFilterBar: true});
          this.setState({productsList: true});

          if(window.location.search.indexOf("sort-by=") > -1) {
            sortBy = decodeURI(window.location.search.split("sort-by=")[1]);
            sortBy = sortBy.split("&")[0];
          }
          this.setState({sortBy: sortBy});

          if(window.location.search.indexOf("per-page=") > -1) {
            perPage = decodeURI(window.location.search.split("per-page=")[1]);
            perPage = perPage.split("&")[0];
          }
          if(window.location.search.indexOf("page-no=") > -1) {
            pageNo = decodeURI(window.location.search.split("page-no=")[1]);
            pageNo = pageNo.split("&")[0];
          }
          if(window.location.search.indexOf("filters=") > -1) {
            selectedFiltersListStr = decodeURI(window.location.search.split("filters=")[1]);
            selectedFiltersListStr = selectedFiltersListStr.split("&")[0];
            let selectedFiltersListArr = selectedFiltersListStr.split(" AND ");
            selectedFiltersListArr.forEach((val) => {
              let paramSplit = val.split(" OR ");
              paramSplit.forEach((val2) => {
                let keySplit = val2.split(":");
                if(keySplit[0].trim()) {
                  if(!selectedFiltersList[keySplit[0]]) {
                    selectedFiltersList[keySplit[0]] = {};
                  }
                  selectedFiltersList[keySplit[0]][keySplit[1]] = keySplit[1];
                } 
              })
            });
          }
          this.setState({keywords: keywords});
          this.setState({selectedFiltersList: selectedFiltersList});
          this.setState({perPage: perPage});
          this.setState({pageNo: parseInt(pageNo)});
          this.getBooks(keywords, pageNo, selectedFiltersListStr, sortBy, perPage);
          window.scrollTo(0, 0);
        }

        if(window.location.pathname.indexOf("/book/") > -1) {
          this.state.showReaderNavigation = false;
          let params = window.location.pathname.split('/');
          if(!navigator.onLine) {
            this.offlineBookStorage(params[3], eval(params[4]) - 1);
          }
          this.setState({readerPage: true});
          this.setState({displayLeftButton: false});
          this.setState({displayFilterBar: false});
          this.setState({
            readerState: {
              ...this.state.readerState,
              loading: true,
              isbn: params[3],
              section: params[4],
              chapterId: params[5],
              category: params[2],
              key: params[4].replace(/[^0-9.]/g, '') - 1,
              page: "reader",
            }
          });

          if(navigator.onLine){
            this.getTitleDetails(params[3]);
          }

          this.setState({'overrideHideSearch' : true})
          var b = document.querySelector('body');
          b.classList.add('reader');
          this.state.readerState.timer = performance.now();
        }

        if(window.location.pathname.indexOf("/download-books") > -1) {
           this.allOfflineBookStorage();
        }

        if(window.location.pathname.indexOf("/product/") > -1) {
          this.setState({productPage: true});
          let params = window.location.pathname.split('/');
          this.setState({readerState: {
            ...this.state.readerState,
            loading: true,
            isbn : params[params.length - 1],
            key: 0,
            chapterId: null,
            category: params[params.length - 3],
            page      : "product"
          }});
          if(navigator.onLine) {
            this.getTitleDetails(params[params.length - 1]);
          }
          this.setState({displayLeftButton: false});
          this.setState({displayFilterBar: false});
        }
      } catch (e) {

      }
      if(localStorage.getItem("tokens")) {
        this.getSubscription();
      }
    }
    if(document.querySelector(".grecaptcha-badge")) {
      document.querySelector(".grecaptcha-badge").style.display = "none";
    }
  }

  getSubscription() {
    getSubscription(this.getSubscriptionCallback.bind(this));
  }

  getSubscriptionCallback(response) {
    if(response.data) {
      this.setState({subscription: response.data});
    }
    this.setState({mask: false});
  }

  setupStorage(){
    this.storage = new Storage();
  }

  allOfflineBookStorage(){
    this.setState({downloaded: this.storage.getAllBooks()});
  }

  offlineBookStorage(isbn, section){
    try {
      let book = this.storage.getBook(isbn);
      if(book){
        this.setState({downloaded: book.content[section]});
        this.setState({chapterCount : Object.keys(book.content) ? Object.keys(book.content).length : 0 })
        this.setState({titleSummary : book.data});
        this.setChapterContent(book.content[section]);
      }
    } catch(e) {
      console.log(e)
    }
  }

  verifyTokensCallback(response) {
    if(response && response.data) {
      var token = {};
      token.refresh_token = response.data.refresh;
      token.access_token = response.data.access;
      token.loggedIn = true;
      this.setTokens(token);
    }
  }

  getBooksCallback(response) {
    this.setState({error: ""});
    if(response.results && response.results[0].hits && response.results[0].hits.length > 0) {
      if((this.state.keywords || Object.keys(this.state.selectedFiltersList).length > 0) && (this.state.nbTotalHits != 0 && response.results[0].nbHits == this.state.nbTotalHits)) {
        return;
      }
      if(!this.state.keywords && Object.keys(this.state.selectedFiltersList).length == 0) {
        this.setState({nbTotalHits:response.results[0].nbHits})
      }
      try {
        this.setState({products:response.results[0].hits});
        if(Object.keys(this.state.facets).length == 0) {
          this.setState({facets:response.results[0].facets});
        }

        this.setState({nbHits:response.results[0].nbHits})
        this.setState({nbPages:response.results[0].nbPages})
      } catch(e) {
        this.setState({products:[]})
        this.setState({nbHits:0})
        if(Object.keys(this.state.facets).length == 0) {
          this.setState({facets:response.results[0].facets});
        }
        this.setState({nbPages:0})
        this.setState({error: "No Record Found!"});
      }
    } else {
      this.setState({products:[]})
      this.setState({nbHits:0})
      if(Object.keys(this.state.facets).length == 0) {
        this.setState({facets:response.results[0].facets});
      }
      this.setState({nbPages:0})
      this.setState({error: "No Record Found!"});
    }
    window.scrollTo(0, 0);
    this.setState({mask: false});
  }

  redirect(page) {
    this.setState({stateUpdate: !this.state.stateUpdate});
    history.push(page)
  }

  toggleModal(key, item) {
    if(key == 'loginModal') {
      this.setState({detailsModal: false});
      this.setState({navModal: false});
      this.setState({loginModal: true});
    }
    if(key == 'detailsModal') {
      this.setState({loginModal: false});
      this.setState({navModal: false});
      this.setState({detailsModal: true})
      this.setState({selectedItem: item})
    }
    if(key == 'navModal') {
      this.setState({loginModal: false});
      this.setState({detailsModal: false});
      this.setState({navModal: item});
    }
  }

  getRecommendTitlesCallback(response) {
    if(response) {
      this.setState({recommendTitles:response.data})
    } else {
      this.setState({error: response.message});
    }
  }

  getExpertsContentful() {
    getExpertsContentful(this.getExpertsContentfulCallback.bind(this));
  }

  getExpertsContentfulCallback(response) {
    if(response) {
      var contentful = {};
      contentful.expertReadings = response;
      this.setState({content:contentful})
    } else {
      this.setState({error: response.errorMessage});
    }
  }downloaded

  getExperts(limit) {
    getExperts(this.getExpertsCallback.bind(this), limit);
  }

  getExpertsCallback(response) {
    this.setState({mask: false});
    if(response) {
      this.setState({experts:response.data})
    } else {
      this.setState({error: response.errorMessage});
    }
  }

  getChapterContentCallBack(response){
    let content = [];
    response.forEach((res, key) => {
        if(res.data) {
          Object.keys(res.data).forEach(key => {
            let formatContent = this.formatCodeBlockContent(res.data[key]);
            content[key] = {content: formatContent}
          });
        }
    });
    this.setChapterContent(content);
  }

  getAllChapterContentCallBack(response){
    let content = [];
    let formatedContent = [];
    response.forEach((res, key) => {
        if(res.data) {
          Object.keys(res.data).forEach(key => {
            let formatContent = this.formatCodeBlockContent(res.data[key]);
            content[key] = {content: formatContent}
          });
        }
    });
    this.state.allChapters.forEach((res, key) => {
      res.forEach((value, index) => {
        if(!formatedContent[key]){
          formatedContent[key] = [];
        }
        if(!formatedContent[key][value]){
          formatedContent[key][value] = [];
        }
        formatedContent[key][value] = content[value];
      });
    });
    this.saveContent(formatedContent);
  }

  saveContent(content){
    this.setState({readerState : {
        ...this.state.readerState,
        downloading: true,
       }
      });
    if(this.storage.saveBook(
        content,
        this.state.readerState.isbn,
        window.location.pathname,
        this.state.titleSummary)
    ){
      this.setState({readerState : {
          ...this.state.readerState,
          downloading: false,
        }
      });
      this.setState({downloaded : this.storage.getAllBooks()});
    }
  }

  setChapterContent(content){
    this.setState({chapterContent : content})
    this.bindCopyButtons();
    this.bindSectionId();
    this.checkForSections();
    this.loadBookSection();
  }

  formatCodeBlockContent(content){
    let newElem = this.returnTheParentNode(content);
    let pre = newElem.getElementsByTagName('pre');
    for (let i = 0; i < pre.length; i++) {
      let el            = pre[i];
      let code          = document.createElement('code');
      let wrapper       = document.createElement('div');
      wrapper.className = "code-toolbar";
      el.parentNode.insertBefore(wrapper, el);
      code.className    = 'language-markup';
      code.innerHTML    = el.innerHTML;
      el.className      = el.className + ' language-markup';
      el.innerHTML      = '';
      el.style          = '';
      el.appendChild(code);
      wrapper.appendChild(el);
      this.addToolbarToCodeBlock(wrapper);
      el.outHtml = wrapper.outerHTML;
    }
    return newElem.innerHTML;
  }

  addToolbarToCodeBlock(element){
    let toolbar           = document.createElement('div');
    toolbar.className     = "toolbar";
    let toolbarItem       = document.createElement('div');
    toolbarItem.className ="toolbar-item"
    let linkCopy = document.createElement('button');
    linkCopy.className = 'copy-to-clipboard-button';
    linkCopy.setAttribute('type', 'button');
    let linkSpan = document.createElement('span');
    linkSpan.textContent = "copy";
    linkCopy.appendChild(linkSpan);
    toolbarItem.appendChild(linkCopy);
    toolbar.appendChild(toolbarItem);
    element.appendChild(toolbar);
  }

  bindCopyButtons(){
    let copybutton = document.getElementsByClassName('copy-to-clipboard-button');
    for(let i = 0 ; i < copybutton.length ; i++ ){
      let toolbar = copybutton[i].closest('.code-toolbar');
      copybutton[i].onclick = () => {
        this.copyTextToClipboard(copybutton[i], toolbar.firstChild.textContent);
      }
    }
  }

  copyTextToClipboard(button,text) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() =>  {
        // try the fallback in case `writeText` didn't work
        this.fallbackCopyTextToClipboard(text);
      });
    } else {
      this.fallbackCopyTextToClipboard(text);
    }

    button.firstChild.textContent = 'Copied!';
    setTimeout(function () {
      button.firstChild.textContent = "Copy";
    }, 3000);
  }


  fallbackCopyTextToClipboard(copyInfo) {
    var textArea = document.createElement('textarea');
    textArea.value = copyInfo;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);
  }

  bindSectionId(){
    try {
      let elements = document.getElementsByClassName('  epub-source');
      let sections = this.state.titleSummary.toc.chapters[this.state.readerState.section - 1].sections;
      for(let i=0; i < elements.length; i ++){
        let cleanString = sections[i].title.replace(/[^A-Z0-9]+/ig, "-");
        elements[i].id = sections[i].id;
        elements[i].setAttribute('data-title', cleanString.toLowerCase());
      }
    } catch(e) {
    }
  }

  checkForSections(){
    let body = document.body;
    body.onscroll = () => {
      let element = document.getElementsByClassName('epub-source')
      for(let i =0; i < element.length; i++){
        let position = element[i].getBoundingClientRect().top;
        if(position < 90 && position > -90){
          let title = element[i].getAttribute('data-title');
          let url = '/book/' + this.state.readerState.category + "/" + this.state.readerState.isbn + "/" + this.state.readerState.section + "/" + element[i].id + "/" + title;
          window.history.pushState(
              {"html": document.html,
                "pageTitle":document.title},
              "", url
          );
          if(this.state.readerState.lastTrackedId !== element[i].id && this.state.tokens.loggedIn){
            this.saveUsage(element[i]);
          }
        }
      }
    }
  }

  saveUsage(element){
    let section = this.state.readerState.section;
    if(!this.state.readerState.completed){
      this.state.readerState.completed = [];
    }
    if(!this.state.readerState.completed[section]){
      this.state.readerState.completed[section] = [];
    }
    if(!this.state.readerState.completed[section][element.id]){
      this.state.readerState.timeSpent = performance.now() - this.state.readerState.timer;
      this.state.readerState.timer = performance.now();
      this.trackUserUsage(element);
      this.state.readerState.completed[section][element.id] = true;
    }
  }

  trackUsageCallBack(response){

  }

  downloadForOffline(val){
    let chapters = this.state.titleSummary.toc.chapters;
    chapters.forEach((element, key) => {
      this.state.allChapters[key] = this.extractChapter(element.sections);
    });
    this.getAllChapterContent();
  }

  deleteOffline(isbn){
    this.setState({ downloaded: this.storage.removeBook(isbn) });
  }

  trackUserUsage(element){
    this.state.readerState.lastTrackedId = element.id;
    if(navigator.onLine) {
      trackReader(
          this.trackUsageCallBack.bind(this),
          this.state.readerState.isbn,
          this.state.readerState.section + "/" + this.state.readerState.chapterId,
          ((this.state.readerState.timeSpent % 60000) / 1000).toFixed(0)
      )
    }
  }

  loadBookSection(){
    if(document.getElementById(this.state.readerState.chapterId)) {
      document.getElementById(this.state.readerState.chapterId).scrollIntoView();
      window.scrollBy(0, -120);
    }
  }

  returnTheParentNode(htmlStr)
  {
    let myCont = document.createElement('DIV'); // create a div element
    myCont.innerHTML = htmlStr; // create its children with the string
    return myCont;  // return the parent div

  }

  getHTML(theNode)
  {
    let myCont = document.createElement('DIV');
    myCont.insertBefore(theNode,null);
    return myCont.innerHTML ;
  }


  getVideoContentCallBack(response) {
    let content = [];
    response.forEach((res, key) => {
      if(res.data){
        content[key] = {content: res.data, captions: null}
        if(res.captions) {
          content[key].captions = res.captions;
        }
      }
    });
    this.setState({chapterContent : content})
  }

  getChapterContent(){
      getChapterContent(this.getChapterContentCallBack.bind(this), this.state.readerState.isbn, this.state.readerState.section, this.state.chapters)
  }

  getAllChapterContent(){
    getAllChapterContent(this.getAllChapterContentCallBack.bind(this), this.state.readerState.isbn, this.state.allChapters);
  }

  getVideoContent(){
    getVideoContent(this.getVideoContentCallBack.bind(this),this.state.readerState.isbn, this.state.readerState.section, this.state.chapters)
  }


  handleFiltersSearch(val) {
    let filters = {};
    let filtersArr = [];
    let filtersStr = [];
    Object.keys(val).forEach((key) => {
      if(Object.keys(val[key]).length > 0) {
        filters[key] = [];
        Object.keys(val[key]).forEach((key2) => {
          filters[key].push(key + ':' + key2)
        });
        filtersArr.push(filters[key].join(" OR "));
      }
    });
    filtersStr = filtersArr.join(" AND ");
    this.setFilters("filters", filtersStr);
    this.setState({filtersModal: false});
  }

  getBooks(keywords, pageNo, selectedFiltersList, sortBy, perPage) {
    getBooks(this.getBooksCallback.bind(this), pageNo, keywords, selectedFiltersList, sortBy, perPage);
  }

  getRecentItems(keywords) {
    getRecentItems(this.getRecentItemsCallback.bind(this), this.state.pageNo, keywords);
  }

  getExpertReaders(param) {
    this.toggleModal("detailsModal");
    this.setState({expertReadersObject: {}});
    getExpertReaders(this.getExpertReadersCallback.bind(this), param);
  }

  getRecommendTitles(keywords) {
    getRecommendTitles(this.getRecommendTitlesCallback.bind(this), this.state.pageNo, keywords);
  }

  getNewReleases(keywords) {
    getNewReleases(this.getNewReleasesCallback.bind(this), this.state.pageNo, keywords);
  }

  getTrendingTitles(keywords) {
    getTrendingTitles(this.getTrendingTitlesCallback.bind(this), this.state.pageNo, keywords);
  }

  getTitleDetails(isbn) {
    getTitleDetails(this.getTitleDetailsCallback.bind(this), isbn);
  }

  getRecentItemsCallback(response) {
    if(response) {
      this.setState({recentItems:response.data})
    } else {
      this.setState({error: response.message});
    }
  }

  getExpertReadersCallback(response) {
    if(response) {
      this.setState({expertReadersObject:response.data});
      this.setState({detailItem: "EXPERTS"});
      this.setState({detailsModal: true});
    } else {
      this.setState({error: response.message});
    }
  }

  getNewReleasesCallback(response) {
    if(response) {
      this.setState({newReleases:response.data})
    } else {
      this.setState({error: response.message});
    }
  }

  getTrendingTitlesCallback(response) {
    if(response) {
      this.setState({trendingTitles:response.data})
    } else {
      this.setState({error: response.message});
    }
  }

  getTitleDetailsCallback(response) {
    this.setState({titleSummary: response.data});
    if(this.state.readerState.loading) {
      if(this.state.readerState.page == "reader") {
        this.getChapters()
      }
      this.state.readerState.loading = false;
      this.state.chapterCount = this.state.titleSummary ? this.state.titleSummary.toc.chapters.length : 0;
    }
  }

  getChapters(){
    if(this.state.titleSummary) {
      this.state.chapterContent = []
      this.state.chapters = []
      let chapters = this.state.titleSummary.toc.chapters;
      let section = this.state.readerState.key;
      chapters.forEach((element, key) => {
        if(key === section){
          this.state.chapters = this.extractChapter(element.sections);
        }
      });
      this.state.readerState.type = this.state.titleSummary.type;
      if(this.state.readerState.type !== 'videos'){
        this.getChapterContent();
      }
      this.getChapterIds(chapters)
    }
  }

  getChapterIds(chapters){
    let section = this.state.readerState.section;
    chapters.forEach((element, key) => {
      if(element.id === section){
        this.state.chapters = this.extractChapter(element.sections);
      }
    });
    this.handleContentCall();
  }

  handleContentCall(){
    switch(this.state.readerState.type){
      case "videos":
        this.getVideoContent();
        break;
      default:
        this.getChapterContent()
       break;
    }
  }

  toggleReaderNavigation(){
    this.setState({showReaderNavigation :  !this.state.showReaderNavigation})
  }

  extractChapter(data){
    let list = [];
    data.forEach((element, key) => {
      list.push(element.id)
    });
    return list;
  }


  handleChangeKeywords(e) {
    this.setState({keywords: e.target.value});
    this.setState({hasNewKeyword: true});
  }

  setIsSearch() {
    this.setState({isSearch: !this.state.isSearch})
  }

  onToggleFilters(val) {
    let closedFilters = this.state.closedFilters;
    if(closedFilters[val]) {
      closedFilters[val] = false;
    } else {
      closedFilters[val] = true;
    }
    this.setState({closedFilters: closedFilters});
  }

  setPopupStatus(val) {
    this.setState({hasPopup: val, showUserNav: val});
  }

  setFilters(key, val) {
    let search = window.location.search.replace("?", "");
    let searchArr = search.split("&");
    let queryParam = [];
    let found = false;
    searchArr.forEach((val2) => {
      let querySplit = val2.split("=");
      if(querySplit[0] == key) {
        querySplit[1] = val;
        found = true;
        queryParam.push(querySplit[0] + "=" + querySplit[1]);
      } else {
        queryParam.push(val2)
      }
    })
    if(!found) {
      queryParam.push(key + "=" + val);
    }
    this.setState({queryParam: queryParam});
    window.history.replaceState(null, null, "/search?" + queryParam.filter(n => n).join("&"));
  }

  setReset() {
    this.setState({loginModal: false});
    this.setState({navModal: false});
    this.setState({detailsModal: false})
    this.setState({filtersModal: false})
    window.history.replaceState(null, null, "/");
  }

  setTokens(token) {
    this.setState({tokens: token});
    localStorage.setItem("tokens", JSON.stringify(token));
    this.setState({loginModal: false});
    this.setState({detailsModal: false});
    this.setState({navModal: false});
  }

  signOut() {
    this.setState({tokens: {}});
    localStorage.removeItem("tokens");
    this.setState({loginModal: false});
    this.setState({subscription: {}});
    this.setState({detailsModal: false});
    this.setState({navModal: false});
  }

  bodyClick() {
    if(this.state.hasNewKeyword) {
      this.setState({hasNewKeyword: false})
    }
    if(this.state.showUserNav) {
      this.setState({showUserNav: false})
    }
    if(this.state.navModal) {
      this.setState({navModal: false})
    }
  }

  render() {
    return (
        <div onClick={() => this.bodyClick()}>
          {this.state.mask && <div className="mask">
            <div className="loader">
              <img src={loader} />
            </div>
          </div>}
          {this.prompt && <InstallPWA />
          }
           {this.state.displayHeader && <Header  redirect={(page) => this.redirect(page)} signOut={() => this.signOut()} tokens={this.state.tokens} setFilters={(key, val) => this.setFilters(key, val)} navModal={this.state.navModal} showUserNav={this.state.showUserNav} setUserNav={(val) => this.setState({showUserNav: val, hasPopup: val})} handleClose={() => this.setState({showSearchBar: false})} setShowSearchBar={() => this.setState({showSearchBar: true})} showSearchBar={this.state.showSearchBar} toggleModal={(val, item) => this.toggleModal(val, item)} hasNewKeyword={this.state.hasNewKeyword} list={this.state.products} keywords={this.state.keywords} setIsSearch={() => this.setIsSearch()} getBooks={(val) => this.getBooks(val, this.state.pageNo, this.state.selectedFiltersList, this.state.sortBy, this.state.perPage)} handleChangeKeywords={(e) => this.handleChangeKeywords(e)} />}
          
          {this.state.navModal && <Nav redirect={(page) => this.redirect(page)} recentItems={this.state.recentItems} tokens={this.state.tokens} toggleModal={(val, item) => this.toggleModal(val, item)} />}
          
          {this.state.displayFilterBar && <div className="mobile-filter col-lg-0">
            <FilterBar sortBy={this.state.sortBy} perPage={this.state.perPage} setFilters={(key, val) => this.setFilters(key, val)} nbHits={this.state.nbHits} keywords={this.state.keywords} setFiltersModal={() => this.setState({filtersModal: true})} />
            <Modal
                isOpen={this.state.filtersModal}
                onRequestClose={() => this.setState({filtersModal: false})}
                style={styles.modalStyle2}
                contentLabel="Information"
                key="filtersModal"
                ariaHideApp={false}
            >
              <Filters setSelectedList={(selectedList) => this.setState({selectedFiltersList: selectedList})} setReset={() => this.setReset()} selectedFiltersList={this.state.selectedFiltersList} handleSearch={(val) => this.handleFiltersSearch(val)} key="mobile-filter" handleClose={() => this.setState({filtersModal: false})} closedFilters={this.state.closedFilters} facets={this.state.facets} onToggle={(val) => this.onToggleFilters(val)} />
            </Modal>
          </div>}

          {!this.state.readerPage && !this.state.productPage && this.state.displayFilterBar && <div className="row grey-bg">
            {this.state.displayLeftButton && <div className="left-button-container col-sm-0">
              {!this.state.displayExpertReadingList  && <button onClick={() => this.setState({displayExpertReadingList: true})} className="btn expert-reading">
                Expert Reading List
              </button>}
              {this.state.displayExpertReadingList && <button onClick={() => this.setState({displayExpertReadingList: false})} className="btn filter">
                Filters
              </button>}
            </div>}


          <div className={"col-lg-3 col-md-3 col-sm-0 trending"}>
              {(!this.state.displayExpertReadingList || this.state.expertsLists) && <div>
                <FilterBar sortBy={this.state.sortBy} perPage={this.state.perPage} setFilters={(key, val) => this.setFilters(key, val)} nbHits={this.state.nbHits} keywords={this.state.keywords} setFiltersModal={() => {}} />
                <hr />
                <Filters setSelectedList={(selectedList) => this.setState({selectedFiltersList: selectedList})} setReset={() => this.setReset()} selectedFiltersList={this.state.selectedFiltersList} handleSearch={(val) => this.handleFiltersSearch(val)} handleClose={() => this.setState({filtersModal: false})} key="desktop-filter" closedFilters={this.state.closedFilters} facets={this.state.facets} onToggle={(val) => this.onToggleFilters(val)} />
              </div>}
              {this.state.displayExpertReadingList && !this.state.expertsLists && <div className="margin-bottom-50">
                <ExpertReadingList loader={this.state.loader}  getExpertReaders={(param) => this.getExpertReaders(param)} isLeftNav={true} expertReadingData={this.state.content.expertReadings} toggleModal={(val, item) => this.toggleModal(val, item)} hasNewKeyword={this.state.hasNewKeyword} list={this.state.experts} keywords={this.state.keywords} />
                <div className="text-center">
                  <hr />
                  <a onClick={() => this.redirect('expert-reading-lists')}>View More</a>
                </div>
              </div>}
            </div>
            <div className={"col-lg-9 col-md-9 col-sm-12 p-0 dashboard-container"}>
              {this.state.displayUpsellBanner && this.state.recentItems && this.state.recentItems.content && <div className="col-sm-0 margin-top-50 text-center">
                <div className="upsell-banner" style={{backgroundImage: "url(" + "https:" + this.state.recentItems.content['assetUrl'] + ")"}}>
                  <h2 className="upsell-banner-title">{this.state.recentItems.content.jsonValue['upsell-title']}</h2>
                  <h6 className="upsell-banner-desc">{this.state.recentItems.content.jsonValue['subtitle']}</h6>
                  <h6><a target="_blank" href={this.state.recentItems.content.jsonValue['btn-link']} className="upsell-banner-button secondary-btn width-200px">{this.state.recentItems.content.jsonValue['btn-title']}</a></h6>
                </div>
              </div>}

              <div className={this.state.displayFilterBar ? "content-listing p-3" : "mt-2"}>
                {
                    this.state.productsList && this.state.products.length > 0 && !this.state.mask && this.state.products.length > 0 && <>
                      <Products nbHits={this.state.nbHits} keywords={this.state.keywords} toggleModal={(val, item) => this.toggleModal(val, item)} hasNewKeyword={this.state.hasNewKeyword} list={this.state.products} keywords={this.state.keywords} />
                      <Pagination setFilters={(key, val) => this.setFilters(key, val)} nbPages={this.state.nbPages} pageNo={this.state.pageNo} />
                    </>
                }

                {this.state.error && <p style={{color: "red", textAlign: "center", paddingTop: 40}}>{this.state.error}</p>}

                {this.state.displayHomeTitles && <div className="margin-top-50">
                  {/*<Banner />*/}
                  <div className="col-12 home-titles">
                    <div><HomeTitles toggleModal={(val, item) => this.toggleModal(val, item)} keywords={""} list={this.state.recentItems} />
                      <HomeTitles toggleModal={(val, item) => this.toggleModal(val, item)} keywords={""} list={this.state.recommendTitles} />
                      <HomeTitles toggleModal={(val, item) => this.toggleModal(val, item)} keywords={""} list={this.state.newReleases} />
                      <HomeTitles toggleModal={(val, item) => this.toggleModal(val, item)} keywords={""} list={this.state.trendingTitles} />
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>}
          {
              this.state.expertsLists &&
              <div className="grey-bg padding-bottom-50">
                <ExpertReadingList loader={this.state.loader} getExpertReaders={(param) => this.getExpertReaders(param)} isLeftNav={false} expertReadingData={this.state.content.expertReadings} toggleModal={(val, item) => this.toggleModal(val, item)} hasNewKeyword={this.state.hasNewKeyword} list={this.state.experts} keywords={this.state.keywords} />
              </div>
          }
          {window.location.pathname.indexOf('/download-books') > -1 &&
              <div className="row grey-bg">
                <div className="col">
                 <OfflineTitles
                     titles={this.state.downloaded}
                     delete={this.deleteOffline.bind(this)}
                   />
                </div>
              </div>
          }

          {this.state.readerPage &&
              <>
                <ReaderNavigation
                    titleSummary={this.state.titleSummary}
                    show={this.state.showReaderNavigation}
                    toggle={(key,val) => this.toggleReaderNavigation(key,val)}
                    state={this.state.readerState}
                    loggedIn={this.state.tokens.loggedIn}
                    downloadOffline={this.downloadForOffline.bind(this)}
                    downloaded={this.state.downloaded}
                />
                <div className="row mt-5">
                  <div className="col-12 mt-5">
                    <Reader
                        titleSummary={this.state.titleSummary}
                        show={this.state.showReaderNavigation}
                        content={this.state.chapterContent}
                        state={this.state.readerState}
                        loggedIn={this.state.tokens.loggedIn}
                        downloaded={this.state.downloaded}
                        chapterCount={this.state.chapterCount}
                    />
                  </div>
                </div>
              </>
          }

          {this.state.productPage &&
              <div className="row">
                <div className="col-12 margin-top-15">
                  <ItemDetails
                      selectedItem={this.state.titleSummary}
                      state={this.state.readerState}
                      tokens={this.state.tokens}
                      toggleModal={(val, item) => this.toggleModal(val, item)}
                      subscription={this.state.subscription}
                  />
                </div>
              </div>
          }

          <Modal
              isOpen={this.state.loginModal}
              onRequestClose={() => this.setState({loginModal: false})}
              style={styles.modalStyle1}
              contentLabel="Information"
              key="loginModal"
              ariaHideApp={false}
          >
            <Login getSubscription={() => this.getSubscription()} setMask={(val) => this.setState({"mask": val})} setTokens={(val) => this.setTokens(val)}  handleClose={() => this.setState({loginModal: false})} />
          </Modal>
          <Modal
              isOpen={this.state.detailsModal}
              onRequestClose={() => this.setState({detailsModal: false})}
              style={styles.modalStyle2}
              contentLabel="Information"
              key="detailsModal"
              ariaHideApp={false}
          >
            {this.state.detailItem == "ITEM" && <ItemDetails subscription={this.state.subscription} handleClose={() => this.setState({detailsModal: false})} selectedItem={this.state.selectedItem} />}
            {this.state.detailItem == "EXPERTS" && <ExpertsDetails handleClose={() => this.setState({detailsModal: false})} selectedItem={this.state.expertReadersObject} />}
          </Modal>
          {/*{navigator.onLine &&*/}
          {/*    <Recaptcha ref={this.refRecaptcha}  sitekey={MIX_RECAPTCHA_PUBLIC_KEY} />*/}
          {/*}*/}
        </div>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
