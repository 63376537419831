import axios from "axios";
import config from "../Config";
import { history } from "../Utils/history";
import { ToastNotifyError } from "../Components/Toast";

const defaultOptions = {
  baseURL: config.report_api,
  headers: {
    "Content-Type": "application/json"
  }
};
const api = axios.create(defaultOptions);

api.defaults.headers["content-type"] = "application/json";

api.interceptors.request.use(item => {
  const token = localStorage.getItem("token");
  item.headers.Authorization = token ? `${token}` : "";
  document.getElementsByClassName("loader")[0].style.display = "flex";
  return item;
});

api.interceptors.response.use(
  resp => {
    document.getElementsByClassName("loader")[0].style.display = "none";
    return resp;
  },
  err => {
    console.log(err, "er");
    const { data, status, response } = err.response;
    document.getElementsByClassName("loader")[0].style.display = "none";
    if (status === 500) {
      ToastNotifyError("500 error. Dotpe Team has been notified.");
    } else if (status === 401) {
      ToastNotifyError("Token expired.. You are logged out !");
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      history.push("/");
    } else if (status === 400) {
      ToastNotifyError(data.message || data.response || "No data found !");
    }
    throw err.response;
  }
);

export default api;
