import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { itemToggle } from "../../Helpers";
import Loading from '../Shared/loader'
import facets from '../../Assets/Static/facets.json';

const Main = props => {
    const [selectedList, setSelectedList] = useState({});
    const handleOnClick = (key1, key2) => {
        key2 = key2.replace("&", "-");
        let selectedListTemp = props.selectedFiltersList;
        if(!selectedListTemp[key1]) {
            selectedListTemp[key1] = {};
        }
        if(selectedListTemp[key1] && selectedListTemp[key1][key2]) {
            delete selectedListTemp[key1][key2];
        } else {
            selectedListTemp[key1][key2] = key2;
        }
        props.setSelectedList(selectedListTemp);
        setSelectedList(selectedListTemp);
    }
    let list = [];
    return (
        <>
        {!Object.keys(props.facets).length > 0 && <Loading
           loading={true}
       />}
        {Object.keys(props.facets).length > 0 && <div className="container-fluid filters-header">
            <div className="row margin-bottom-20">
                <div className="col-6">
                    <span>Filter Results</span>
                </div>
                <div className="col-6 filter-reset pointer">
                    <span onClick={() => props.setReset()}>Reset All</span>
                </div>
            </div>
            {
                Object.keys(facets).forEach((key, val) => {
                    let keywordsList = [];
                    if(facets[key]['order_by'] == "desc") {
                        Object.keys(props.facets[key]).sort().reverse().forEach((key2, val2) => {
                            if(facets[key]['hide_keys'].indexOf(key2) == -1) {
                                key2 = key2.replace("-", "&");
                                keywordsList.push(<div key={"filter-" + key2} className={props.selectedFiltersList[key] && props.selectedFiltersList[key][key2] ? "row text-orange" : "row"} onClick={() => handleOnClick(key, key2)}>
                                    <div className="col-10">
                                        {key2}
                                    </div>
                                    <div className="col-2">
                                        {props.facets[key][key2]}
                                    </div>
                                </div>)
                            }
                        })
                    } else {
                        Object.keys(props.facets[key]).forEach((key2, val2) => {
                            if(facets[key]['hide_keys'].indexOf(key2) == -1) {
                                key2 = key2.replace("-", "&");
                                keywordsList.push(<div key={"filter-" + key2} className={props.selectedFiltersList[key] && props.selectedFiltersList[key][key2.replace("&", "-")] ? "row text-orange" : "row"} onClick={() => handleOnClick(key, key2)}>
                                    <div className="col-10">
                                        {key2}
                                    </div>
                                    <div className="col-2">
                                        {props.facets[key][key2]}
                                    </div>
                                </div>)
                            }
                        })
                    }
                    list.push(<><div className="row filter-title" onClick={() => props.onToggle(key)}>
                        <div className="col-10">
                            <h5>
                                {facets[key]["label"]}
                                {props.selectedFiltersList[key] && Object.keys(props.selectedFiltersList[key]).length > 0 && <span className="text-orange padding-left-5">
                  ({Object.keys(props.selectedFiltersList[key]).length} Selected)
                </span>}
                            </h5>
                        </div>
                        <div className="col-2">
                            {props.closedFilters[key] && <h5 className="text-orange"><FontAwesomeIcon icon={faAngleUp} /></h5>}
                            {!props.closedFilters[key] && <h5 className="text-orange"><FontAwesomeIcon icon={faAngleDown} /></h5>}
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-12">
                                {props.closedFilters[key] && <div id={"collapse-" + key} className="filter-keys">
                                    <ul>{keywordsList}</ul>
                                </div>}
                            </div>
                        </div></>)
                })
            }
            <div className="filters">{list}</div>
            <div className="row margin-top-15 margin-bottom-50">
                <div className="col-6 col-lg-0">
                    <button onClick={() => props.handleClose()} className="primary-btn">Cancel</button>
                </div>
                <div className="col-6 col-lg-12 col-md-12 col-sm-6">
                    <button onClick={() => props.handleSearch(props.selectedFiltersList)} className="primary-btn green">Apply</button>
                </div>
            </div>
        </div>}
        </>
    );
}

export default Main;