import React, { useState } from "react";
import { StarRating } from "../../Components";
import {PRODUCT_URL} from '../../Config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Loading from '../Shared/loader'
import * as moment from 'moment';

const Main = props => {
  let keywords = props.keywords.toLowerCase();
  let listArr = [];
  return (
    <div className="margin-top-50">
      {props.loading && <Loading
           loading={true}
       />}
      {!props.loading && <div className={!props.isLeftNav ? "container-fluid mt-5 pt-5" : "container-fluid"}>
        <h5 className="text-orange">{props.expertReadingData.data ? props.expertReadingData.data["expert-network.page.content.header"].jsonValue.title : ""}</h5>
        <h6 className="text-gray">{props.expertReadingData.data ? props.expertReadingData.data["expert-network.page.content.header"].jsonValue.description: ""}</h6>
        <hr />
        <div className="row">
          {
            props.list.forEach((val) => {
              let isbns = val.isbns.split(",");
              if(val.title.toLowerCase().indexOf(keywords) > -1 || val.author.join(" ").toLowerCase().indexOf(keywords) > -1) {
                listArr.push(<div onClick={() => props.getExpertReaders(val.url)} key={"product-" + val.id} className={window.location.pathname == "/expert-reading-lists" ? "col-lg-3 col-md-3 col-sm-12 col-12 reading-list-media media" : "col-lg-12 col-md-12 col-sm-12 col-12 reading-list-media media"}>
                  <div className="img-list list-size-3">
                    <a>
                      <img className="img-0" src={PRODUCT_URL + isbns[0] + "/cover/smaller"} />
                      <img className="img-1" src={PRODUCT_URL + isbns[1] + "/cover/smaller"} />
                      <img className="img-2" src={PRODUCT_URL + isbns[2] + "/cover/smaller"} />
                    </a>
                  </div>
                  <div className={"media-body"}>
                  <h6 className="text-blue">
                      {val.title}
                  </h6>
                  <h6>{val.description.length > 100 ? val.description.substring(0, 100) + ".." : val.description}</h6>
                  <div className="row">
                  {val.curation_date && <div className="col-6"><h6 className="text-gray">
                    <small>
                        Created - {moment(val.curation_date).format('MMM YYYY')}
                    </small>
                  </h6></div>}
                  {val.name && <div className="col-6 text-right"><h6 className="text-gray">
                    <small>
                        {val.name}
                    </small>
                  </h6></div>}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-10 text-gray"><h6>{isbns.length} items in the reading list</h6></div>
                    <div className="col-2 text-orange text-right">
                      <h6><FontAwesomeIcon icon={faAngleDown} /></h6>
                    </div>
                  </div>
                  </div>
                </div>);
              }
            })
          }
          {listArr}  
        </div>
      </div>}
    </div>
  );
}

export default Main;