import React from 'react';
import ReactDOM from 'react-dom';
// LIBRARIES - REDUX
import { Provider } from 'react-redux';
// CONTAINERS
import App from './App';
// REDUX
import store from "./Redux/Store";
import 'bootstrap/dist/css/bootstrap.min.css';

function startApp() {

    invokeServiceWorker();

    ReactDOM.render(
        <Provider store={store}>
          <App />
        </Provider>,
        document.getElementById('root'));
        document.addEventListener("backbutton", function(e){
           navigator.app.exitApp();
    }, false);
}

function invokeServiceWorker(){
    let newWorker;
    let refreshing;
    // The click event on the notification
    if(document.getElementById('reload')) {
        document.getElementById('reload').addEventListener('click', function () {
            newWorker.postMessage({action: 'skipWaiting'});
        });
    }
    if (navigator.serviceWorker) {
        navigator.serviceWorker.register('service-worker.js').then(function(registration) {
            registration.addEventListener('updatefound', () => {

                // An updated service worker has appeared in reg.installing!
                newWorker = registration.installing;

                newWorker.addEventListener('statechange', () => {

                    // Has service worker state changed?
                    switch (newWorker.state) {
                        case 'installed':

                            // There is a new service worker available, show the notification
                            if (navigator.serviceWorker.controller) {
                                let notification = document.getElementById('notification ');
                                notification.className = '';
                            }

                            break;
                    }
                });
            });
        }).catch(function(error) {
            console.log('ServiceWorker registration failed:', error);
        });

        navigator.serviceWorker.addEventListener('controllerchange', function () {
            if (refreshing) return;
            window.location.reload();
            refreshing = true;
        });
    }
}

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}
